<template>
  <ul :id="'children-'+parent">
    <li style="padding-left: 0;margin-bottom:20px;" v-for="node in GetTeamNodes(nodes)" :id="'node-'+node.hashid">
        <b-card class="relative text-left mb-0 item">
          <div class="d-flex justify-content-between mb-0">
            <div class="goal-header d-flex align-items-center team-goalheader">
              <b-img :src="getIcon(node)" class="w-100 border-0" rounded alt="profile photo"></b-img>
              <div class="employee-detail">
                <h5 class="mb-0"  v-if="node.type == 'Individual OKR' " :title="node.user.name" v-b-tooltip.hover.v-default>{{node.user.name}}</h5>
                <h5 class="mb-0"  v-else :title="node.type" v-b-tooltip.hover.v-default>{{node.type}}</h5>
              </div>
            </div>
          </div>
          <div class="team-name d-flex justify-content-between mt-1">
            <b-link class="mb-0 font-weight-bolder" v-b-tooltip.hover.v-default
            :title="node.title" v-b-modal.goal-details @click="getOKR(node)"  style="font-size: 16px;">{{node.title}}</span></b-link>
            <b-badge variant="info">{{node.progress}}%</b-badge>
          </div>
          <hr>
          <div class="d-flex align-items-center">
            <span class="small">End at | {{node.end_date|formatDate}}</span>
          </div>
          <b-button :id="'node-'+node.hashid+'-node-arrow'" class="expand-icon arrow"  v-if="node.children.length" @click="listClicked(node)"><feather-icon icon="ChevronRightIcon" size="20" class="text-primary font-weight-bolder"/></b-button>
        </b-card>
          <node v-if="node.children.length" :nodes.sync="node.children" :parent="node.hashid" :selectedOKR.sync="okr"></node>
    </li>
    <li style="padding-left: 0;" v-for="node in GetPeopleNodes(nodes)" :id="'node-'+node.hashid">
      <b-card class="relative text-left mb-0 item">
        <div class="d-flex justify-content-between mb-0">
          <div class="goal-header d-flex align-items-center team-goalheader">
            <b-img :src="getIcon(node)" class="w-100 border-0" rounded alt="profile photo"></b-img>
            <div class="employee-detail">
              <h5 class="mb-0"  v-if="node.type == 'Individual OKR' " :title="node.user.name" v-b-tooltip.hover.v-default>{{node.user.name}}</h5>
              <h5 class="mb-0"  v-else :title="node.type" v-b-tooltip.hover.v-default>{{node.type}}</h5>
            </div>
          </div>
        </div>
        <div class="team-name d-flex justify-content-between mt-1">
          <b-link class="mb-0 font-weight-bolder" v-b-tooltip.hover.v-default
          :title="node.title" v-b-modal.goal-details @click="getOKR(node)"  style="font-size: 16px;">{{node.title}}</span></b-link>
          <b-badge variant="info">{{node.progress}}%</b-badge>
        </div>
        <hr>
        <div class="d-flex align-items-center">
          <span class="small">End at | {{node.end_date|formatDate}}</span>
        </div>
        <b-button :id="'node-'+node.hashid+'-node-arrow'" class="expand-icon arrow"  v-if="node.children.length" @click="listClicked(node)"><feather-icon icon="ChevronRightIcon" size="20" class="text-primary font-weight-bolder"/></b-button>
      </b-card>
        <node v-if="node.children.length" :nodes.sync="node.children" :parent="node.hashid" :selectedOKR.sync="okr"></node>
  </li>
  </ul>
</template>

<script>
  import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Node from "./node.vue";
import axios from "@axios";
import $ from "jquery";
export default {
  name:"node",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    vSelect,
    Node
  },
  props: {
    nodes: {
      type: Array,
      required: false,
    },
    parent: {
      type: String,
      required: true,
    },
    selectedOKR:{
    }
  },
  directives: {
    Ripple,
  },
 mounted () {
    $('.okr-tree ul').hide();
    $('.okr-tree>ul').show();
    $('.teamview-tree ul.active').hide();
  },
  data() {
    return {
      okr:{}
    }
  },
  watch: {
    okr(val) {
      this.$emit('update:selectedOKR',val);
    },
  },
  methods: {
    GetTeamNodes(nodes){
      var results = [];
      for (let index = 0; index < nodes.length; index++) {
        const node = nodes[index];
        if(node.type == "Team OKR"){
          results.push(node);
        }
      }
      return results;
    },
    GetPeopleNodes(nodes){
      var results = [];
      for (let index = 0; index < nodes.length; index++) {
        const node = nodes[index];
        if(node.type == "Individual OKR"){
          results.push(node);
        }
      }
      return results;
    },
    getIcon(okr){
      if(okr.type == 'Individual OKR'){
        return okr.user.avatar;
      }
      if(okr.type == 'Team OKR'){
        return require('@/assets/images/icons/okr/okr-team.png');
      }
      return require('@/assets/images/elements/decore-left.png');
    },
    getOKR(okr){
      axios
        .get("okrs/"+okr.hashid)
        .then((response) => {
          this.$emit('update:selectedOKR',response.data.data);
        });
    },
    listClicked(node){
      node.opened = true;
      var nodeId = '#node-'+node.hashid;
      $('#children-'+this.parent+" li .arrow").removeClass("activenode");
      $(nodeId).addClass("open-tree");      
      $(nodeId).siblings('li').removeClass('open-tree');
      $(nodeId).siblings('li').find('ul').hide();
      var children = $(nodeId).find('> ul');
      if (children.is(":visible")) {
        children.hide('fast').removeClass('active');
      }
      else {
        children.show('fast').addClass('active');
        $(nodeId+"-node-arrow").addClass("activenode");
      }  
    }
  },
  computed:{
  }
}
</script>