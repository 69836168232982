<template>
  <div class="team-goal">
 <b-card class="mb-1">
    <!-- Top header section -->
    <b-row> 
      <b-col md="4" sm="12" class="d-flex align-items-center">
        <b-button class="d-flex align-items-center" v-b-toggle.showtreeview @click="isListViewOpen = false" squared variant="outline-primary"><b-img :src="require('@/assets/images/icons/okr/okr-tree.png')" class="border-0 mr-25" width="16" alt="tree view" />Tree</b-button>
        <b-button v-b-toggle.showlistview @click="getOKRList(); isListViewOpen = true" class="m-0 d-flex align-items-center" squared variant="outline-primary"><b-img :src="require('@/assets/images/icons/okr/okr-list.png')"  class="border-0 mr-25" width="16" alt="list view" />List</b-button>
      </b-col>
      <b-col  md="4" sm="12" class="toolbar text-right" v-if="roots.length > 0">
        <b-input-group
          class="input-group-merge round"
          v-if="isListViewOpen"
        >
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" 
            v-b-tooltip.hover.v-default
            title="Search"/>
          </b-input-group-prepend>
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block"
            placeholder="Search Title or OKR Owner"
          />
        </b-input-group>
        </b-col>
      <b-col  md="4" sm="12" class="toolbar text-right" v-if="roots.length > 0">
        <v-select
              class="select-program"
              label="label"
              :options="RootList"
              :reduce="(label) => label.code"
              :clearable="false"
              :searchable="true"
              v-model="selectedRoot"
              @input="getOKRTree(selectedRoot)"
              name="root_okr"
              placeholder="Select Root OKR"
            />
        </b-col>
    </b-row>
</b-card>
  <b-row class="team-toolbar">
    <b-col>
      <b-collapse class="body teamview-body teamgoal-scroll px-0" id="showtreeview" visible accordion="my-accordion" role="tabpanel">
        <div class="mx-5 px-5 mobile-design">
          <b-row v-if="!nodes" class="d-flex justify-content-center">
            <b-col md="7">
              <b-card
                title="Not Enough OKRs to form an tree."
                :img-src="
                  require('@/assets/images/pages/coming-soon.svg')
                "
                img-alt="Image"
                img-top
                tag="article"
                class="mb-2"
                sub-title="There must be a Organization OKR"
                
              >
                <b-card-text>
                  Team OKRs and Individual OKRs must be linked to each other or to Organization OKR
                </b-card-text>
                <div class="d-flex justify-content-center">
                  <b-button  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="$router.push('/individual-okrs')">
                    <feather-icon icon="PlusIcon" /> New OKR
                  </b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
        
        <!-- Horizontal View Design -->
          <div class="tree-goal" v-if="nodes">
            
            <ul id="tree-view" style="padding-left: 0;" >
              <li style="padding-left: 0;" id="root">
                <b-card class="relative text-left mb-0 item">
                  <div class="d-flex justify-content-between mb-0">
                    <div class="goal-header d-flex align-items-center team-goalheader">
                      <b-img :src="require('@/assets/images/icons/okr/okr-company.png')" class="w-100 border-0" rounded alt="profile photo"></b-img>
                      <div class="employee-detail">
                        <h5 class="mb-0" v-b-tooltip.hover.v-default :title="nodes.type">{{nodes.type}}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="team-name d-flex justify-content-between mt-1">
                    <b-link class="mb-0 font-weight-bolder" v-b-tooltip.hover.v-default
                    :title="nodes.title" v-b-modal.goal-details  @click="getOKR(nodes)" style="font-size: 16px;">{{nodes.title}}</span></b-link>
                    <b-badge variant="info">{{nodes.progress}}%</b-badge>
                  </div>
                  <hr>
                  <div class="d-flex align-items-center">
                    <span class="small">End at | {{nodes.end_date|formatDate}}</span>
                  </div>
                  <b-button id="root-node-arrow" class="expand-icon " v-if="nodes.children && nodes.children.length"  @click="listClicked('root')" ><feather-icon icon="ChevronRightIcon" size="20" class="text-primary font-weight-bolder"/></b-button>
                </b-card>
                <node v-if="nodes.children && nodes.children.length" :nodes.sync="nodes.children" :parent.sync="nodes.hashid" :selectedOKR.sync="selectedOKR"></node>
              </li>
            </ul>
          </div>
        <!-- End Horizontal View Design -->
      </b-collapse>
      <b-collapse class="teamlist-view" id="showlistview" accordion="my-accordion" role="tabpanel">
        <b-row>
          <b-col cols="12">
          <!-- Filters -->
          <app-collapse>
            <app-collapse-item :isVisible="false" title="Filters">
              <b-card-body>
                <b-row>
                  <b-col cols="12" md="3" sm="6" class="mb-md-0 mb-2">
                    <label>OKR Type</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="typeFilter"
                      :options="typeOptions"
                      class="w-100"
                      :reduce="(val) => val.code"
                      v-model="typeFilter"
                      placeholder="Filter By OKR Type.."
                    />
                  </b-col>
                  <b-col cols="12" md="3" sm="6" class="mb-md-0 mb-2">
                    <label>Reporting Manager</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="managerFilter"
                      :options="managerOptions"
                      class="w-100"
                      :reduce="(val) => val.code"
                      v-model="managerFilter"
                      placeholder="Filter By Manager.."
                    />
                  </b-col>
                  <b-col cols="12" md="3" sm="6" class="mb-md-0 mb-2">
                    <label>Employee</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="employeeFilter"
                      :options="employeeOptions"
                      class="w-100"
                      :reduce="(val) => val.code"
                      v-model="employeeFilter"
                      placeholder="Filter By Employee.."
                    />
                  </b-col>

                  <b-col cols="12" md="3" sm="6" class="mb-md-0 mb-2">
                    <label>OKR State</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="stateFilter"
                      :options="okrStateOption"
                      class="w-100"
                      :reduce="(val) => val.value"
                      v-model="stateFilter"
                      placeholder="Filter OKR State.."
                    />
                  </b-col>         
                  <b-col cols="12" md="3" sm="6" class="mb-md-0 mb-2 mt-1">
                    <label>Parent OKR</label>
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="parentFilter"
                      :options="parentFilterOption"
                      class="w-100"
                      :reduce="(val) => val.code"
                      v-model="parentFilter"
                      placeholder="Filter By Parent OKR.."
                    />
                  </b-col>     
                  <b-col cols="12" md="3" sm="6" class="mb-md-0 mb-2 mt-1">
                    <label>Date</label>
                    <flat-pickr
                      v-model="selectedDate"
                      :config="{ dateFormat: 'Y-m-d' }"
                      class="form-control"
                      name="date"
                      placeholder="Filter by date.."
                    />  
                  </b-col>  
                  <!-- Month Year Selectbox -->
                  <!-- <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2 mt-1 wrap-drop" id="custom-select">
                    <label>Month & Year</label>
                    <span>Filter by Month & Year..</span>
                    <ul class="drop">
                      <li value="march23">Mar 2023</li>
                      <li value="feb23">Feb 2023</li>
                      <li value="jan23">Jan 2023</li>
                      <li class="line-divider"><hr class="m-0" /></li>
                      <li value="dec22">Dec 2022</li>
                      <li value="nov22">Nov 2022</li>
                      <li value="oct22">Oct 2022</li>
                      <li value="sep22">Sep 2022</li>
                      <li value="aug22">Aug 2022</li>
                      <li value="jul22">Jul 2022</li>
                      <li value="jun22">Jun 2022</li>
                      <li value="may22">May 2022</li>
                      <li value="apr22">Apr 2022</li>
                      <li value="mar22">Mar 2022</li>
                      <li value="feb22">Feb 2022</li>
                      <li value="jan22">Jan 2022</li>
                      <li class="line-divider"><hr class="m-0" /></li>
                      <li value="dec21">Dec 2021</li>
                      <li value="nov21">Nov 2021</li>
                      <li value="oct21">Oct 2021</li>
                      <li value="sep21">Sep 2021</li>
                      <li value="aug21">Aug 2021</li>
                      <li value="jul21">Jul 2021</li>
                      <li value="jun21">Jun 2021</li>
                      <li value="may21">May 2021</li>
                      <li value="apr21">Apr 2021</li>
                      <li value="mar21">Mar 2021</li>
                      <li value="feb21">Feb 2021</li>
                      <li value="jan21">Jan 2021</li>
                      <li class="line-divider"><hr class="m-0" /></li>
                      <li value="dec20">Dec 2020</li>
                      <li value="nov20">Nov 2020</li>
                      <li value="oct20">Oct 2020</li>
                      <li value="sep20">Sep 2020</li>
                      <li value="aug20">Aug 2020</li>
                      <li value="jul20">Jul 2020</li>
                      <li value="jun20">Jun 2020</li>
                      <li value="may20">May 2020</li>
                      <li value="apr20">Apr 2020</li>
                      <li value="mar20">Mar 2020</li>
                      <li value="feb20">Feb 2020</li>
                      <li value="jan20">Jan 2020</li>
                      <li class="line-divider"><hr class="m-0" /></li>
                      <li value="dec19">Dec 2019</li>
                      <li value="nov19">Nov 2019</li>
                      <li value="oct19">Oct 2019</li>
                      <li value="sep19">Sep 2019</li>
                      <li value="aug19">Aug 2019</li>
                      <li value="jul19">Jul 2019</li>
                      <li value="jun19">Jun 2019</li>
                      <li value="may19">May 2019</li>
                      <li value="apr19">Apr 2019</li>
                      <li value="mar19">Mar 2019</li>
                      <li value="feb19">Feb 2019</li>
                      <li value="jan19">Jan 2019</li>
                    </ul>
              </b-col> -->
                  <!-- End -->
                  <b-col cols="12" md="3" sm="6" class="mb-md-0 mb-2 mt-1">
                    <label>Month</label><br>
                  <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :value="monthFilter"
                      :options="monthOptions"
                      class="w-100"
                      :reduce="(val) => val.code"
                      v-model="monthFilter"
                      placeholder="Filter Month.."
                    />
                  </b-col>
                  <b-col cols="12" md="3" sm="6" class="mb-md-0 mb-2 mt-1">
                    <label>Year</label>
                    <b-form-spinbutton
                      v-model="yearFilter"
                      min="2020"
                      :max="new Date().getFullYear()"
                    />
                  </b-col>
                  <b-col cols="12" md="3" sm="6" class="mb-md-0 mb-2 mt-1">
                    <!-- basic -->
                    <b-form-group
                      :label="'By OKR Completion Percentage - ' + completionValue + '%'"
                      label-for="completion"
                    >
                      <b-form-input
                       style="margin-top:8px;"
                        type="range"
                        min="0"
                        max="100"
                        v-model="completionValue"
                        id="completion"
                        name="completion"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="3" sm="6" class="mb-md-0 mb-2 mt-1">
                    <label>Reset Filter</label><br>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-primary"
                      @click="reset"
                    >
                      Reset
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
              
            </app-collapse-item>
          </app-collapse>
          </b-col>
        </b-row>

		<!-- OKRs Lists -->
    <div class="mt-1 d-flex justify-content-center">
      <b-row v-if="okrs.length == 0">
        <b-col md="12">
          <b-card
            title="Records Not Found"
            :img-src="
              require('@/assets/images/pages/coming-soon.svg')
            "
            img-alt="Image"
            img-top
            tag="article"
            class="mb-2"
            
          >
            <b-card-text>
              Try switching organization OKR or resetting Filter.
            </b-card-text>
            <div class="d-flex justify-content-center">
              <b-button  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="$router.push('/individual-okrs')">
                <feather-icon icon="PlusIcon" /> New OKR
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    
      <div class="mx-5 px-5 mobile-design" v-if="okrs.length">
        <b-row class="p-1 justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-row>
        <!-- Team OKRs Lists -->
        <b-card class="okrs-list mb-1" v-for="okr in okrs" :key="okr.hashid">
          <!-- <b-row>
            <b-col class="d-flex align-items-center"   v-b-modal.goal-details  @click="selectedOKR = okr">
              <b-img-lazy fluid :src="okr.user.avatar" alt="goal-img" width="30px" height="30px" class="border-primary" />
              <h5 class="goal-name font-weight-bolder mb-0 ml-50">{{okr.user.name}}</h5>
            </b-col>
            <b-col class="align-items-center d-flex justify-content-end"  >
              <h5 class="font-weight-bolder m-0 px-1"  v-b-modal.goal-details  @click="selectedOKR = okr">{{okr.title}}</h5>
              <div class="date-finish date-deadline mx-1"  v-b-modal.goal-details  @click="selectedOKR = okr">
                <span class="text-danger">{{okr.end_date}}</span>
              </div>
              <template #cell(progress)="data">
                <strong>{{ data.item.progress }}%</strong>
                <b-progress
                  key="progress"
                  animated
                  variant="primary"
                  class="'progress-bar-primary"
                >
                  <b-progress-bar :value="data.item.progress" variant="primary">
                  </b-progress-bar>
                </b-progress>
              </template>
              <b-badge variant="primary">{{okr.progress}} %</b-badge>
              <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default title="More Options"/>
                </template>
                <b-dropdown-item class="btn-sm p-0" v-b-modal.view-okrs @click="selectedOKR = okr"
                v-if="
                $can('Zircly Admin') ||
                ($can('okr_view') && (okr.user.hashid == userDataLocal.hashid || okr.created_by.hashid == userDataLocal.hashid))
              ">
                  <feather-icon icon="EyeIcon" size="13" class="mr-50 text-primary"/>
                  <span class="">View</span>
                </b-dropdown-item>
                <b-dropdown-item class="btn-sm p-0" v-b-modal.edit-okr @click="selectedOKR = okr"
                v-if="
                $can('Zircly Admin') ||
                ($can('okr_edit') && (okr.user.hashid == userDataLocal.hashid || okr.created_by.hashid == userDataLocal.hashid))
              ">
                  <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary"/>
                  <span class="">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item class="btn-sm p-0" 
                @click="confirmDeleteRecord(okr)"
                v-if="
                  $can('Zircly Admin') ||
                  ($can('okr_delete') && (okr.user.hashid == userDataLocal.hashid || okr.created_by.hashid == userDataLocal.hashid))
                ">
                  <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary"/>
                  <span class="">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row> -->
          <b-row class="mb-1">
            <b-col v-b-modal.goal-details @click="okrOpened(okr)" class="d-flex align-items-center justify-content-between" sm="12" md="5">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="36"
                  :src="getOKRIcon(okr)"
                  style="background:transparent;"
                  rounded="0"
                />
                  <h4 class="font-weight-bolder mb-0 ml-25">
                    {{okr.title}}
                  </h4>
                </div>
                <b-dropdown class="blog-dropdown d-md-none" variant="link" size="sm" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default title="More Options"/>
                </template>
                <b-dropdown-item class="btn-sm p-0" v-b-modal.edit-okr @click="okrOpened(okr)"  v-if="
                ($can('Zircly Admin') || isCreator(okr)) &&
                $can('okr_edit')
              ">
                  <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary"/>
                  <span class="">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="okr.hashid"
                      v-clipboard:copy="shareUrl(okr.hashid)"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      class="btn-sm p-0 text-muted"
                    >
                      <feather-icon
                        icon="ShareIcon"
                        size="13"
                        class="mr-50 text-primary"
                      />
                      <span class="">Share</span>
                    </b-dropdown-item>
                <b-dropdown-item class="btn-sm p-0" 
                @click="confirmDeleteRecord(okr)"
                v-if="
                  $can('Zircly Admin') ||
                  $can('okr_delete')
                ">
                  <!-- @click="editRecord(data)" -->
                  <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary"/>
                  <span class="">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col class="text-right mobile-left justify-content-md-end" sm="12" md="7">
              <b-badge pill variant="light" class="border text-dark goal-tag mr-1">
                <b-img-lazy fluid :src="require('@/assets/images/icons/targeting-indi.png')" alt="goal-icon" width="16px" height="16px"/><span class="px-1">{{okr.okr_type}}</span>
              </b-badge>
              <b-badge variant="light-primary" class="mr-1" v-if="userDataLocal.hashid == okr.created_by.hashid && okr.user.hashid != okr.created_by.hashid">Created by you</b-badge>
              <b-badge variant="primary" v-if="okr.okr_state == 'Completed'">{{okr.okr_state}}</b-badge>
              <b-badge variant="secondary" v-if="okr.okr_state == 'Not started yet'">{{okr.okr_state}}</b-badge>
              <b-badge variant="warning" v-if="okr.okr_state == 'In progress'">{{okr.okr_state}}</b-badge>
              <!-- <feather-icon icon="UsersIcon" size="20" class="text-body align-middle ml-2 text-muted" v-b-tooltip.hover.v-default title="Visible to everyone"/> -->
              <b-dropdown class="blog-dropdown d-none d-md-inline" variant="link" size="sm" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default title="More Options"/>
                </template>
                <b-dropdown-item class="btn-sm p-0" v-b-modal.edit-okr @click="okrOpened(okr)"  v-if="
                ($can('Zircly Admin') || isCreator(okr)) &&
                $can('okr_edit')
              ">
                  <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary"/>
                  <span class="">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="okr.hashid"
                      v-clipboard:copy="shareUrl(okr.hashid)"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      class="btn-sm p-0 text-muted"
                    >
                      <feather-icon
                        icon="ShareIcon"
                        size="13"
                        class="mr-50 text-primary"
                      />
                      <span class="">Share</span>
                    </b-dropdown-item>
                <b-dropdown-item class="btn-sm p-0" 
                @click="confirmDeleteRecord(okr)"
                v-if="
                  $can('Zircly Admin') ||
                  $can('okr_delete')
                ">
                  <!-- @click="editRecord(data)" -->
                  <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary"/>
                  <span class="">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        <hr>
          <h5 class="goal-name font-weight-bolder  text-capitalize mt-50" v-b-modal.goal-details @click="okrOpened(okr)" > Owned By : <strong>{{okr.user.name}}</strong></h5>
          <!-- <div class="goal-footer d-flex" v-b-modal.goal-details @click="selectedOKR = okr">
            <span class="action">{{okr.description}}</span>
          </div> -->
        </b-card>
        <!-- End Team OKRs Lists -->
      </div>
      </b-collapse>
    </b-col>
  </b-row>
    <b-sidebar title="Sidebar" no-header :sidebar-class="fullScreen ? 'modal-full-screen' : 'modal-responsive-width'" right bg-variant="white" backdrop width="30%" ref="teamgoalView" shadow id="teamgoal-view">
      <template #default="{ hide }">
        <b-link
            v-if="!fullScreen"
            class="text-primary floating-close"
            @click="hide"
          >
            <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
          </b-link>
          <!-- Header -->
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              content-sidebar-header
              px-2
              py-2
              border-bottom
              sticky-top
            "
          >
            <h4 class="mb-0 font-weight-bold blog-title goal-header"><b-img :src="require('@/assets/images/icons/okr/okr-company.png')" class="w-100" rounded fluid alt="profile photo"></b-img>Team Name</h4>
            <div class="d-flex align-items-center">

              <b-link
                v-if="fullScreen"
                class="btn btn-icon rounded-circle btn-outline-danger mr-1 p-50 close-icon"
                @click="hide"
              >
                <feather-icon icon="XIcon" size="18" class="" />
              </b-link>

              <b-link
                class="btn btn-icon rounded-circle btn-outline-secondary mr-1 p-50 fullscreen-icon"
                @click="fullScreen = !fullScreen"
              >
                <feather-icon
                  :icon="!fullScreen ? 'Maximize2Icon' : 'Minimize2Icon'"
                  size="18"
                  class=""
                />
              </b-link>
            </div>
          </div>
          <b-card class="modal-body p-0">
          <h3 class="font-weight-bolder mb-1">Revenue of 7 M</h3>
          <div class="d-flex justify-content-between">
            <div class="goal-owner d-flex align-items-center">
              <b-avatar icon="people-fill"></b-avatar>
              <div class="pl-1">
              <h6 class="font-weight-bolder">OKR owner</h6>
              <span>Karthik Chidambaram</span>
              </div>
            </div>
            <div class="date-finish">
              <h6 class="font-weight-bolder">End Date</h6>
              <span>December 31, 2023</span>
            </div>
        </div>
        <hr>
        <div class="goal-progress">
          <h5 class="font-weight-bolder my-1 font-weight-bolder">OKR Report <span><b-badge pill variant="info">On Track</b-badge></span></h5>
          <div class="goal-detailed-progress d-flex justify-content-between align-items-center">
            <div class="progress-bar mr-1">
              <div class="progress-view" role="progressbar" style="width: 0%;"></div>
            </div>
            <span class="progress-report">0%</span>
          </div>
        <hr>
          <h6 class="small font-weight-bolder mt-2 font-weight-bolder">Key Results</h6>
          <b-list-group>
            <b-list-group-item class="border-0 px-0">
              <feather-icon icon="FlagIcon" size="16" class="text-body"/>
              New clients
            </b-list-group-item>

            <b-list-group-item class="border-0 px-0">
              <feather-icon icon="FlagIcon" size="16" class="text-body"/>
              Sales effort of 5M
            </b-list-group-item>
          </b-list-group>
        </div>
        <hr>
        <div class="sub-okrs">
          <h4 class="mb-1 font-weight-bolder">Sub Team OKRs</h4>
          <b-card class="card border set-goal mb-1" v-b-modal.teamgoal-details>
            <div class="d-flex justify-content-between">
              <div class="goal-header d-flex">
                <b-img :src="require('@/assets/images/elements/decore-left.png')" class="w-100" rounded fluid alt="profile photo"></b-img>
                <div class="employee-detail">
                  <h5 class="mb-0">User Name</h5>
                    <h6 class="small">Frontend Developer</h6>
                </div>
              </div>
              <h5 class="font-weight-bolder pt-1">December OKR</h5>
            </div>
          </b-card>
          <b-card class="card border set-goal mb-1">
            <div class="d-flex justify-content-between">
              <div class="goal-header d-flex">
                <b-img :src="require('@/assets/images/elements/decore-left.png')" class="w-100" rounded fluid alt="profile photo"></b-img>
                <div class="employee-detail">
                  <h5 class="mb-0">KalaiselviC</h5>
                    <h6 class="small">Product Team ( Zircly )</h6>
                </div>
              </div>
              <h5 class="font-weight-bolder pt-1">January OKR</h5>
            </div>
          </b-card>
          <b-card class="card border set-goal mb-1">
            <div class="d-flex justify-content-between">
              <div class="goal-header d-flex">
                <b-img :src="require('@/assets/images/elements/decore-left.png')" class="w-100" rounded fluid alt="profile photo"></b-img>
                <div class="employee-detail">
                  <h5 class="mb-0">Guna</h5>
                    <h6 class="small">Product Team ( Zircly )</h6>
                </div>
              </div>
              <h5 class="font-weight-bolder pt-1">January OKR</h5>
            </div>
          </b-card>
        </div>
        <div class="d-flex align-items-start mb-1">
          <b-img-lazy fluid :src="require('@/assets/images/avatars/1.png')" alt="user-img" width="32px" height="32px" class="mr-75"/>
            <div class="profile-user-info w-100">
              <div class="d-flex align-items-center justify-content-between">
                <div class="goal-owner d-flex align-items-center">
                  <div>
                    <h6><b>UserName</b></h6>
                    <small>User Designation</small>
                  </div>
                </div>
                <div class="d-flex align-items-start mb-1">
                  <span class="small mr-50 ">1 hour ago</span>
                  <b-link class="btn btn-icon text-primary rounded-circle p-0"><feather-icon icon="CornerUpLeftIcon" size="18"  v-b-toggle.add-cmd/></b-link>
                  <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0" style="margin-top: 2px" no-caret>
           	    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle text-muted" v-b-tooltip.hover.v-default title="More Options"/>
                    </template>
                    <b-dropdown-item class="btn-sm p-0">
                      <feather-icon icon="CornerUpLeftIcon" size="13" class="mr-50 text-primary"/>
                      <span class="">Reply to comment</span>
                    </b-dropdown-item>
                    <b-dropdown-item class="btn-sm p-0">
                      <feather-icon icon="LockIcon" size="13" class="mr-50 text-primary"/>
                      <span class="">Make a Private</span>
                    </b-dropdown-item>
                    <b-dropdown-item class="btn-sm p-0">
                      <feather-icon icon="BookOpenIcon" size="13" class="mr-50 text-primary"/>
                      <span class="">Make a Public</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <!-- <p
                    v-html="comment.comment"
                    class="mb-0 mt-1"
                    style="letter-spacing: 1px; font-size: 14px"
                  ></p> -->
                  <p
                    class="mb-0 mt-1"
                    style="letter-spacing: 1px; font-size: 14px"
                  >Way to represent! Thank you for your leadership and your participation. Rock on!</p>
            </div>
          </div>
      </b-card>
      </template> 
    </b-sidebar>
    <!-- <b-modal id="teamgoal-details">
			<b-row class="mb-2">
				<b-col>
					<b-img-lazy fluid :src="require('@/assets/images/icons/target.png')" alt="goal-img" width="30px" height="30px"/>
					<b-badge variant="primary">Completed</b-badge>
					<b-badge pill variant="light" class="border text-dark goal-tag">
            <b-img-lazy fluid :src="require('@/assets/images/icons/tree-view.png')" alt="goal-icon" width="16px" height="16px"/><span class="px-1">Self-development</span>
					</b-badge>
				</b-col>
				<b-col class="text-md-right">
					<feather-icon icon="UserIcon" size="16" class="text-body align-middle ml-2 text-muted" v-b-tooltip.hover.v-info title="Your direct manager and select managers report to will see the goal"/>
					<b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none" no-caret>
						<template v-slot:button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default title="More Options"/>
            </template>
						<b-dropdown-item class="btn-sm p-0" v-b-modal.edit-okr @click="selectedOKR = okr">
							<feather-icon icon="EditIcon" size="13" class="mr-50 text-primary"/>
							<span class="">Edit</span>
            </b-dropdown-item>
						<b-dropdown-item
                      v-clipboard:copy="shareUrl(okr.hashid)"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      class="btn-sm p-0 text-muted"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        size="13"
                        class="mr-50 text-primary"
                      />
                      <span class="">Copy</span>
                    </b-dropdown-item>
						<b-dropdown-item class="btn-sm p-0" 
            @click="confirmDeleteRecord(okr)"
            v-if="
              $can('Zircly Admin') ||
              $can('okr_delete')
            ">
							<feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary"/>
							<span class="">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
				</b-col>
			</b-row>
			<h5 class="goal-name font-weight-bolder">Product Improvement</h5>
			<div class="goal-view my-1">
				<h5>What do you want to accomplish?</h5>
				<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
			</div>
			<div class="goal-view my-1">
				<h5>What do you want to accomplish?</h5>
				<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
			</div>
		</hr>
		<template #modal-footer="{ hide }">
      <b-button size="sm" variant="outline-secondary">
        Mark as completed
      </b-button>
    </template>
  </b-modal> -->

  <!-- Modals -->
  <b-modal ref="edit_okr" id="edit-okr" centered size="xl" style="background-color: transparent;" ok-title="Update" @ok.prevent="formUpdated()" @shown="showOkrForm" no-close-on-backdrop>
    <template #modal-header="{ close }">
      <h5 class="mb-0 font-weight-bolder">What do you want to accomplish?</h5>
      <b-button size="sm" variant="outline-transparent" class="p-0" @click="close()">
        <feather-icon icon="XIcon" size="24" class="text-body align-middle ml-2"/>
      </b-button>
    </template>
    <h4 class="text-center pt-1 font-weight-bolder">We all have things to get done. No matter how big or small, it starts with a goal. </h4>
    <h5 class="text-center mb-2">So let’s get started!</h5>

    <b-form autocomplete="off" id="okr-form">
        <b-card class="border">
          <b-card-text class="d-flex align-items-center border-bottom-primary pb-1">
            <b-img-lazy fluid :src="require('@/assets/images/icons/target.png')" alt="goal-img" width="30px" height="30px"/>
            <input ref="okrname" v-model="selectedOKR.title" class="font-weight-bold mb-0 ml-1 border-0 w-100 font-weight-bolder" style="font-size:20px;" type="text" placeholder="Enter the OKR title here..." />
            <!-- <h4 class="font-weight-bold mb-0 ml-1">OKR Title</h4> -->
          </b-card-text>
          <b-row>
            <b-col md="6">
              <b-form-group label="OKR Period Range" label-for="period_range">
                <flat-pickr
                  ref="period_range"
                  class="form-control"
                  input-id="period_range"
                  placeholder="Select Start and End Date"
                  v-model="selectedOKR.period_range"
                  :config="dateConfig"
                  @input="rangeChanged"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="OKR Type" label-for="okr_type">
                          <v-select
                            :options="okrTypeList"
                            label="label"
                            :reduce="(label) => label.code"
                            :clearable="true"
                            :searchable="true"
                            input-id="okr_type"
                            name="okr_type"
                            disabled
                            placeholder="Select OKR Type"
                            v-model="selectedOKR.okr_type"
                            :value.sync="selectedOKR.okr_type"
                          />
                        </b-form-group>
            </b-col>
            <b-col md="6" 
                  v-if="$can('Zircly Admin') || $can('Manager')">
                    <b-form-group label="OKR Owner" label-for="okr_owner">
                                <v-select
                                  :options="employeeOptions"
                                  label="label"
                                  :reduce="(label) => label.code"
                                  :clearable="true"
                                  :searchable="true"
                                  input-id="okr_owner"
                                  name="okr_owner"
                                  placeholder="Select OKR Owner"
                                  v-model="selectedOKR.okr_owner"
                                />
                              </b-form-group>
                  </b-col>
          </b-row>
          <b-alert show>
            <b-row class="px-2 py-1">
              <b-col cols="auto">
                <b-img-lazy fluid :src="require('@/assets/images/icons/idea.png')" alt="goal-img" width="30px" height="30px"/>
              </b-col>
              <b-col>
                <p class="text-dark">Capture what you hope to accomplish with your goal. Try to keep it specific and objective-focussed. Struggling with a title? Skip this section for now and return to it later.</p>
              </b-col>
            </b-row>      
          </b-alert>
        </b-card>
        <b-card class="border obj-main">
<div class="obj-header">
            <b-row>
              <b-col>
            <h4>Define your Objectives and Key Results</h4>
            <h5 class="small">Use this SMART framework to create effective okrs that keep you headed in the right direction.</h5>
              </b-col>
              <b-col sm="12" md="3" class="text-md-right">
                <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary"
            @click="addOnSelected()"
          >
          <feather-icon icon="PlusIcon" 
          size="15"/> Add Objective
          </b-button>
          </b-col>
        </b-row>
          <hr>
</div>
          <b-row v-if="selectedOKR.okr_details" class="obj-details">
            <b-col md="12" v-for="(action_item,k) in selectedOKR.okr_details" :key="k">
              <div style="border: 2px solid #38B22D;padding: 15px;border-radius: 50px 20px;" class="mt-1 relative">
                <b-button @click="removeFromSelected(k)" style="background-color: #fff !important;border-radius: 100%;padding: 0;width: 32px;height: 32px;display: flex;align-items: center;justify-content: center;position: absolute;top: 0;right: 0;"
                        >
                        <feather-icon icon="TrashIcon" size="13" class="text-danger" 
                        v-b-tooltip.hover.v-danger
                        title="Remove Objective"/>
                      </b-button>
                <h5 class="obj-title">  Objective {{k+1}} </h5>
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group label="Type" label-for="objective_type">
                      <v-select
                        :options="typeList"
                        label="label"
                        :reduce="(label) => label.code"
                        :clearable="true"
                        :searchable="true"
                        input-id="objective_type"
                        name="objective_type"
                        placeholder="Select OKR Type"
                        v-model="selectedOKR.okr_details[k].type"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- <b-col sm="12" md="6">
                    <b-form-group label="Status" label-for="status">
                      <v-select
                        :options="statusList"
                        label="label"
                        :reduce="(label) => label.code"
                        :clearable="true"
                        :searchable="true"
                        input-id="status"
                        name="status"
                        placeholder="Select Status"
                        v-model="selectedOKR.okr_details[k].status"
                      />
                    </b-form-group>
                    </b-col> -->
                  <b-col sm="12" md="12">
                    <b-form-group
                        label="Objective"
                        label-for="objective"
                      >
                      <b-form-textarea
                        id="objective"
                        placeholder="Enter the Objective in Short"
                        v-model="selectedOKR.okr_details[k].objective"
                        rows="2"
                      />
                      </b-form-group>
                  </b-col>
                  
                  <b-col sm="12" md="12">
                    <!-- <b-form-group
                      label="Key Result"
                      label-for="key_result"
                      icon="CheckSquareIcon"
                    > -->

                    <!-- <b-form-textarea
                      id="key_result"
                      placeholder="Enter the Key Result in Short"
                      rows="2"
                      v-model="selectedOKR.okr_details[k].key_result"
                    /> -->
                    <!-- </b-form-group>   -->
                    <label for="key_result" class="d-block">Key Results</label>
                    <div class="demo-inline-spacing">
                      <a @click="addKeyResult(k,'update')" class="font-weight-bolder text-primary">
                        <feather-icon icon="PlusIcon" class="font-weight-bolder"/> Add Key Result</a>
                    </div>
                    <b-row>
                      <b-col cols="12" class="mb-1" v-for="(key_result,key) in selectedOKR.okr_details[k].key_results" :key="key">
                        <div class="border border-primary mt-1 relative" style="border: 2px solid #38B22D;border-radius:10px 10px;padding: 15px;">
                          <b-row>
                            <b-button @click="removeKeyResult(k,key,'update')" style="background-color: #fff !important;border-radius: 100%;padding: 0;width: 32px;height: 32px;display: flex;align-items: center;justify-content: center;position: absolute;top: 0;right: 0;"
                              >
                              <feather-icon icon="XIcon" size="13" class="text-danger" 
                              v-b-tooltip.hover.v-danger
                              title="Remove Key Result"/>
                            </b-button>
                            <b-col sm="12">
                              <b-form-group
                                  :label="'Key Result #'+(key+1)"
                                  label-for="key-result"
                                >
                                <b-form-textarea
                                  id="key-result"
                                  placeholder="Enter the Key Result in Short"
                                  v-model="selectedOKR.okr_details[k].key_results[key].description"
                                  rows="2"
                                />
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4">
                              <b-form-group label="Status" label-for="status">
                                <v-select
                                  :options="statusList"
                                  label="label"
                                  :reduce="(label) => label.code"
                                  :clearable="true"
                                  :searchable="true"
                                  input-id="status"
                                  name="status"
                                  placeholder="Select Status"
                                  v-model="selectedOKR.okr_details[k].key_results[key].status"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4">
                              <b-form-group label="Priority Level" label-for="priority-level">
                                <v-select
                                  :options="priorityList"
                                  label="label"
                                  :reduce="(label) => label.code"
                                  :clearable="true"
                                  :searchable="true"
                                  input-id="priority-level"
                                  name="priority-level"
                                  placeholder="Select Priority Level"
                                  v-model="selectedOKR.okr_details[k].key_results[key].priority_level"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col md="4" sm="12">
                              <b-form-group label="Timeline" label-for="period_range">
                                <flat-pickr
                                  ref="timeline"
                                  class="form-control"
                                  input-id="timeline"
                                  placeholder="Select Start and End Date"
                                  v-model="selectedOKR.okr_details[k].key_results[key].timeline"
                                  :config="keyResultDateConfig"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                       <!-- Bottom Add Key Results -->
                       <div class="demo-inline-spacing show-keyplus w-100 d-flex align-items-center justify-content-center">
                              <a @click="addKeyResult(k,'update')" class="font-weight-bolder text-white bg-primary">
                                <feather-icon icon="PlusIcon" class="font-weight-bolder"/> <span class="hover-btn">Add Key Result</span></a>
                            </div>
                            <!-- End Bottom Add Key Results -->
                    </b-row>
                    
                  </b-col>
                  <!-- <b-col sm="12" md="4">
                    <b-form-group label="Metric Type" label-for="metric_type">
                      <b-form-radio-group
                        size="lg"
                        id="btn-radios-1"
                        v-model="selectedOKR.okr_details[k].metric_type"
                        button-variant="outline-primary text-primary"
                        :options="metricOption"
                        buttons
                        name="radios-btn-default"
                      />
                       <b-button-group
                        size="lg"
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          v-b-tooltip.hover.v-default
                          title="Numeric"
                          @click="selectedOKR.okr_details[k].metric_type = 'Numeric'"
                        >
                          <strong>123</strong>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          v-b-tooltip.hover.v-default
                          title="Percentage"
                          @click="selectedOKR.okr_details[k].metric_type = 'Percentage'"
                        >
                        <strong>%</strong>
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="selectedOKR.okr_details[k].metric_type = 'Yes/No'"
                          v-b-tooltip.hover.v-default
                          title="Yes/No"
                        >
                          <feather-icon icon="CheckSquareIcon" size="20" class="text-primary"/>
                        </b-button>
                      </b-button-group> 
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="4" v-if="selectedOKR.okr_details[k].metric_type != 'Yes/No'  && selectedOKR.okr_details[k].metric_type != '' ">
                    <b-form-group label="Start" label-for="start">
                    <b-input-group>
                    <b-form-input
                      id="start"
                      type="number"
                      v-model="selectedOKR.okr_details[k].start"
                    />
                    <b-input-group-append is-text v-if="selectedOKR.okr_details[k].metric_type == 'Percentage'">
                      <feather-icon
                        icon="PercentIcon"
                        class="text-primary"
                      />
                    </b-input-group-append>
                  </b-input-group> 
                </b-form-group> 
                  </b-col>
                  <b-col sm="12" md="4" v-if="selectedOKR.okr_details[k].metric_type != 'Yes/No' && selectedOKR.okr_details[k].metric_type != '' ">
                    <b-form-group label="End" label-for="end">
                    <b-input-group
                      label="end"
                      label-for="End"
                    >
                    <b-form-input
                      id="end"
                      type="number"
                      v-model="selectedOKR.okr_details[k].end"
                    />
                    <b-input-group-append is-text v-if="selectedOKR.okr_details[k].metric_type == 'Percentage'">
                      <feather-icon
                        icon="PercentIcon" class="text-primary"
                      />
                    </b-input-group-append>
                  </b-input-group>  
                  </b-form-group>
                  </b-col> -->
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="border" title="Alignment" v-if="data_local.okr_type != 'Organizational OKR' " sub-title="Adding a parent goal allows your team to be aligned with the whole organization">
          <b-row>
            <b-col md="6">
              <b-form-group label="Parent OKR Type" label-for="parent_okr_type">
                <v-select
                  :options="parentOkrTypeList"
                  label="label"
                  :reduce="(label) => label.code"
                  :clearable="true"
                  :searchable="true"
                  input-id="parent_okr_type"
                  name="parent_okr_type"
                  placeholder="Select Parent OKR Type"
                  v-model="selectedOKR.parent_okr_type"
                  :value.sync="selectedOKR.parent_okr_type"
                  @input="parentTypeChanged() "
                />
                </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Parent OKR" label-for="parent_okr">
                  <v-select
                    :options="parentOptions"
                    label="label"
                    :clearable="true"
                    :searchable="true"
                    input-id="parent_okr"
                    name="parent_okr"
                    v-model="selectedOKR.parent_okr_hashid"
                    placeholder="Select Parent OKR"
                    :reduce="(label) => label.code"
                    :value.sync="selectedOKR.parent_okr_hashid"
                  />
                </b-form-group>
            </b-col>
          </b-row>
        </b-card>
    </b-form>
  </b-modal>

  <b-modal ref="goal_details" id="goal-details" hide-footer size="lg">
    <template #modal-header="{ close }" class="d-flex align-items-center">
      <b-row class="w-100">
        <b-col class="d-flex align-items-center">
          <b-img-lazy fluid :src="require('@/assets/images/icons/target.png')" alt="goal-img" width="30px" height="30px"/>
          <span class="font-weight-bolder text-uppercase" style="margin-left: 5px;">OKR View</span>
        </b-col>
        <b-col class="text-right">
          <b-badge pill variant="light" class="border border-primary text-dark goal-tag ml-2">
            <b-img-lazy fluid :src="require('@/assets/images/icons/tree-view.png')" alt="goal-icon" width="16px" height="16px"/><span class="px-1">{{selectedOKR.okr_type}}</span>
          </b-badge>
        </b-col>
      </b-row>
<b-button size="sm" variant="outline-transparent" class="p-0" @click="close()">
      <feather-icon icon="XIcon" size="24" class="text-body align-middle ml-2"/>
    </b-button>
    </template>
    <b-card variant="trasaprent" class="border mt-1">
    <b-row class="mb-2">
      <b-col>
        <h2 class="goal-name font-weight-bolder">{{selectedOKR.title}}</h2>
        <!-- <p class="mb-0">{{selectedOKR.description}}</p> -->
      </b-col>
      <b-col class="text-md-right">
        <!-- <feather-icon icon="UsersIcon" size="16" class="text-body align-middle ml-2 text-muted" v-b-tooltip.hover.v-info title="Your direct manager and select managers report to will see the goal"/> -->
        <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none" no-caret>
          <template v-slot:button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default title="More Options"/>
          </template>
          <b-dropdown-item class="btn-sm p-0" v-b-modal.edit-okr   v-if="
          ($can('Zircly Admin') || isCreator(selectedOKR)) &&
          $can('okr_edit')
        ">
            <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary" />
            <span class="">Edit</span>
          </b-dropdown-item>
          <b-dropdown-item
          @click="copyURL(selectedOKR.hashid)"
                    >
                      <feather-icon
                        icon="ShareIcon"
                        size="13"
                        class="mr-50 text-primary"
                      />
                      <span class="">Share</span>
                    </b-dropdown-item>
          <b-dropdown-item class="btn-sm p-0"  
          @click="confirmDeleteRecord(selectedOKR)"
          v-if="
            $can('Zircly Admin') ||
            $can('okr_delete')
          ">
            <!-- @click="editRecord(data)" -->
            <feather-icon icon="TrashIcon" size="13" class="mr-50 text-primary"/>
            <span class="">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="goal-owner d-flex align-items-center">
        <div class="owner-info d-flex align-items-center pr-2 border-right">
          <b-avatar
            size="lg"
            :src="getOKRIcon(selectedOKR)"
            style="background:transparent;"
            rounded="0"
          />
          <div class="pl-1">
          <h6 class="font-weight-bolder">OKR owner</h6>
          <span>{{selectedOKR.user.name}}</span>
          </div>
        </div>
        <div class="date-info d-flex align-items-center">
          <div class="date-finish ml-2  pr-2 border-right">
            <h6 class="font-weight-bolder">Start Date</h6>
            <span>{{selectedOKR.start_date|formatDate}}</span>
          </div>
          <div class="date-finish ml-2">
            <h6 class="font-weight-bolder">End Date</h6>
            <span>{{selectedOKR.end_date|formatDate}}</span>
          </div>
        </div>
      </b-col>
    </b-row>
    <hr>
    <div class="goal-progress">
      <b-row justify-content-between>
        <b-col>
        <h4 class="font-weight-bolder my-1 font-weight-bolder">OKR Report <span><b-badge pill variant="info" class="mx-1 small">{{selectedOKR.okr_state}}</b-badge></span></h4>
        </b-col>
        <!-- <b-col class="text-right">
        <b-button variant="outline-primary">Progress View</b-button>
        </b-col> -->
      </b-row>
      <b-progress
        :value="(selectedOKR.progress > 0)?selectedOKR.progress : 0"
        max="100"
        class="progress-bar-primary"
        variant="primary"
        animated
      >
      <b-progress-bar :value="selectedOKR.progress">
        <strong>{{ selectedOKR.progress}}%</strong>
      </b-progress-bar>
      </b-progress>
  </div>
  <h5 class="font-weight-bolder mt-2 font-weight-bolder">Objectives and Key Results</h5>
  <b-tabs class="okr-objdetail">
    <!-- top position -->
    <b-tab :title='"Objectives("+getObjectives.length+")"' active>
      <b-tabs v-for="(objective,k) in getObjectives" :key="k"
        vertical
        nav-wrapper-class="nav-vertical" class="mb-2 mt-2">
        <b-tab
          active
          :title='"Objective "+(k+1)'
        >
          <b-card-text >
            <b-row>
            <b-col cols="12" class="object-type">
              <b-row>
                <b-col sm="6" md="3" lg="2">
                  <b-badge
                    :variant="`${getStatusClass(getObjectiveStatus(objective))}`"
                    class="badge"
                  >
                    {{getStatusLabel(getObjectiveStatus(objective))}}
                  </b-badge>
                </b-col>
                <b-col sm="12" md="6" lg="8">
                <strong :class="`text-${getStatusClass(getObjectiveStatus(objective))}`">
                  {{objective.objective}}
                </strong>
                <b-badge
                    :variant="`${getStatusClass(getObjectiveStatus(objective))}`"
                    class="badge"
                  >
                    {{getObjectiveRatio(objective)}}
                  </b-badge>
                </b-col>
                <b-col sm="4" md="3" lg="2" class="text-md-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    v-if="
                    ($can('Zircly Admin') || isCreator(selectedOKR)) &&
                    $can('okr_edit')
                  "
                  @click="selectedObjective = objective"
                  v-b-modal.update-goal
                  class="edit-btn px-2 py-75"
                  >
                    Edit
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" style="margin-top:5px;">
              <b-row>
                <b-col cols="12">
                  <b-badge
                    variant="success"
                    class="badge"
                  >
                  Key Results
                  </b-badge>
                </b-col>
                <b-col cols="12">
                  <!-- <strong class="text-success">
                    {{selectedOKR.okr_details[k].key_results[key].description}}
                  </strong>  -->
                  <b-row>
                    <b-col cols="12" class="mb-1" v-for="(key_result,key) in objective.key_results" :key="key">
                      <div class="border border-primary mt-1 relative" style="border: 2px solid #38B22D; border-radius: 10px 10px;padding: 15px;">
                        <b-row>
                          <b-col sm="12" md="12">
                            <b-form-group
                                :label="'Key Result #'+(key+1)"
                                label-for="key-result"
                              >
                              <strong class="text-success">
                                {{key_result.description}}
                              </strong> 
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="4">
                            <b-form-group label="Status" label-for="status">
                              <b-badge
                                :variant="`${getStatusClass(key_result.status)}`"
                                class="badge"
                              >
                                {{getStatusLabel(key_result.status)}}
                              </b-badge>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="4">
                            <b-form-group label="Priority Level" label-for="priority-level">
                              <b-badge
                                :variant="`${getPriorityClass(key_result.priority_level)}`"
                                class="badge"
                              >
                                {{getPriorityLabel(key_result.priority_level)}}
                              </b-badge>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group label="Timeline" label-for="period_range">
                              <b-badge
                                variant="success"
                                class="badge"
                              >
                                {{key_result.timeline|formatDateRange}}
                              </b-badge>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="12" v-if="key_result.notes">
                            <b-form-group
                                :label="'Key Result Notes #'+(key+1)"
                                label-for="key-result-notes"
                              >
                              <strong class="text-success">
                                {{key_result.notes}}
                              </strong> 
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row> 
            </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-tab>


    <!-- left position -->
    <b-tab
      :title='"Experiments("+getExperiments.length+")"'
    >
      <b-tabs v-for="(experiment,k) in getExperiments" :key="k"
        vertical
        nav-wrapper-class="nav-vertical" class="mb-2 mt-2">
        <b-tab
          :title='"Experiment "+(k+1)'
        >
          <b-card-text >
            <b-row>
            <b-col cols="12">
              <b-row>
                <b-col sm="6" md="3" lg="2">
                  <b-badge
                    :variant="`${getStatusClass(getObjectiveStatus(experiment))}`"
                    class="badge"
                  >
                    {{getStatusLabel(getObjectiveStatus(experiment))}}
                  </b-badge>
                </b-col>
                <b-col sm="12" md="6" lg="8">
                  <strong :class="`text-${getStatusClass(getObjectiveStatus(experiment))}`">
                    {{experiment.objective}}
                  </strong>
                  <b-badge
                    :variant="`${getStatusClass(getObjectiveStatus(experiment))}`"
                    class="badge"
                  >
                    {{getObjectiveRatio(experiment)}}
                  </b-badge>
                </b-col>
                <b-col sm="4" md="3" lg="2" class="text-md-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    v-if="
                    ($can('Zircly Admin') || isCreator(selectedOKR)) &&
                    $can('okr_edit')
                  "
                  @click="selectedObjective = experiment"
                  v-b-modal.update-goal
		  class="edit-btn px-2 py-75"
                  >
                    Edit
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12" style="margin-top:5px;">
              <b-row>
                <b-col cols="12">
                  <b-badge
                    variant="success"
                    class="badge"
                  >
                  Key Results
                  </b-badge>
                </b-col>
                <b-col cols="12">
                  <!-- <strong class="text-success">
                    {{objective.key_result}}
                  </strong>  -->
                  <b-row>
                    <b-col cols="12" class="mb-1" v-for="(key_result,key) in experiment.key_results" :key="key">
                      <div class="border border-primary mt-1 relative" style="border: 2px solid #38B22D; border-radius: 10px 10px;padding: 15px;">
                        <b-row>
                          <b-col sm="12" md="12">
                            <b-form-group
                                :label="'Key Result #'+(key+1)"
                                label-for="key-result"
                              >
                              <strong class="text-success">
                                {{key_result.description}}
                              </strong> 
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="4">
                            <b-form-group label="Status" label-for="status">
                              <b-badge
                                :variant="`${getStatusClass(key_result.status)}`"
                                class="badge"
                              >
                                {{getStatusLabel(key_result.status)}}
                              </b-badge>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="4">
                            <b-form-group label="Priority Level" label-for="priority-level">
                              <b-badge
                                :variant="`${getPriorityClass(key_result.priority_level)}`"
                                class="badge"
                              >
                                {{getPriorityLabel(key_result.priority_level)}}
                              </b-badge>
                            </b-form-group>
                          </b-col>
                          <b-col md="4">
                            <b-form-group label="Timeline" label-for="period_range">
                              <b-badge
                                variant="success"
                                class="badge"
                              >
                                {{key_result.timeline|formatDateRange}}
                              </b-badge>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="12" v-if="key_result.notes">
                            <b-form-group
                                :label="'Key Result Notes #'+(key+1)"
                                label-for="key-result-notes"
                              >
                              <strong class="text-success">
                                {{key_result.notes}}
                              </strong> 
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row> 
            </b-col>
            <!-- <b-col md="12" v-if="experiment.notes" style="margin-top:5px;">
              <b-row>
                <b-col cols="2">
                  <b-badge
                    variant="warning"
                    class="badge"
                  >
                  Experiment Note
                  </b-badge>
                </b-col>
                <b-col cols="10">
                  <strong class="text-warning">
                    {{experiment.notes}}
                  </strong> 
                </b-col>
              </b-row> 
            </b-col> -->
            </b-row>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-tab>
  </b-tabs>
  </b-card>
  <!-- <h4 class="font-weight-bolder">Activity</h4>
  <b-card class="border">
    <b-list-group>
      <b-list-group-item>
        <div class="goal-owner d-flex align-items-center pr-2 border-right">
          <b-img-lazy fluid :src="require('@/assets/images/avatars/1.png')" alt="goal-img" width="42px" height="42px" fluid/>
          <div class="pl-1">
          <h6><b>You</b> updated the goal status to Delayed.</h6>
          <span>2 days ago</span>
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="selectedOKR.created_by">
        <div class="goal-owner d-flex align-items-center pr-2 border-right">
          <b-img-lazy fluid :src="selectedOKR.created_by.avatar" alt="goal-img" width="42px" height="42px" fluid/>
          <div class="pl-1">
          <h6><b>{{selectedOKR.created_by.name}}</b> created the OKR.</h6>
          <span>{{selectedOKR.created_at}}</span>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card> -->
  <!-- Sub OKRs-->
  <b-row class="mb-1">
    <b-col><h4 class="font-weight-bolder">Sub-OKRs</h4></b-col>
    <!-- <b-col class="text-right">
      <a v-b-toggle href="#expand-notes" @click.prevent class="font-weight-bolder"><feather-icon icon="PlusIcon" class="font-weight-bolder"/> Add Sub-okrs</a>
    </b-col> -->
  </b-row>
  <b-card class="border">
    <b-list-group>
      <b-list-group-item v-if="selectedOKR.sub_okrs.length && selectedOKR.hashid != sub_okr.hashid" v-for="sub_okr in selectedOKR.sub_okrs" :key="sub_okr.hashid">
        <b-row class="goal-owner d-flex align-items-center" v-if="selectedOKR.hashid != sub_okr.hashid">
          <b-col md="5" class="d-flex align-items-center">
            <b-img-lazy fluid 
            :src="getOKRIcon(sub_okr)"
            style="background:transparent;" alt="goal-img" width="36px" height="36px" fluid/>
            <h4 class="text-capitalize mb-0 ml-1">{{sub_okr.title}}</h4>
          </b-col>
          <b-col md="7" class="justify-content-end d-flex align-items-center">
            <span>{{sub_okr.user.name}}</span>
            <span class="px-1">{{sub_okr.end_date|formatDate}}</span>
            <div class="goal-detailed-progress d-flex justify-content-between align-items-center">
              <div class="progress-bar mr-1">
                <div class="progress-view" role="progressbar" style="width: 20%;"></div>
              </div>
              <h5 class="progress-report mb-0">{{sub_okr.progress}}%</h5>
            </div>
            <b-badge pill variant="info" class="ml-1 small">{{sub_okr.okr_state}}</b-badge>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item v-if="selectedOKR.sub_okrs && (selectedOKR.sub_okrs.length == 0 || (selectedOKR.sub_okrs.length == 1 && selectedOKR.hashid == selectedOKR.sub_okrs[0].hashid))">
        No Sub OKRs....
      </b-list-group-item>
  </b-list-group>
  </b-card>
  <!-- End Sub OKRs-->
  <!-- Comment Section -->
  <h4 class="font-weight-bolder mb-1">Comments</h4>
  <div class="post-card common-cmd">
    <b-card class="shadow-none mb-0">
      <b-list-group>
        <!-- <b-list-group-item>
          <div class="goal-owner d-flex align-items-center pr-2 border-right">
            <b-img-lazy fluid :src="require('@/assets/images/avatars/1.png')" alt="goal-img" width="42px" height="42px" fluid/>
            <div class="pl-1">
            <h6><b>You</b> updated the goal status to Delayed.</h6>
            <span>2 days ago</span>
            </div>
          </div>
        </b-list-group-item> -->
        <b-list-group-item v-for="comment in VisibleComments" :key="comment.hashid" class="bg-transparent reply-post py-50 border-0 px-0">
          <div class="inner-cmd">
            <div class="d-flex align-items-start">
              <b-img-lazy fluid :src="comment.user.avatar" alt="user-img" width="32px" height="32px" class="mr-75"/>
              <div class="profile-user-info w-100 right-cmt">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="goal-owner d-flex align-items-center">
                    <div>
                      <h6><b>{{comment.user.name}}</b></h6>
                      <small>{{ comment.user.designation }}</small>
                    </div>
                  </div>
                  <div class="d-flex align-items-start mb-1">
                    <b-badge variant="primary" class="mr-1 d-none d-lg-block" v-if="comment.visiblity_type == 'Private' " >Private</b-badge>
                    <b-badge variant="light-primary" class="mr-1 d-none d-lg-block" v-if="comment.visiblity_type == 'Public' " >Public</b-badge>
                    <span class="small mr-50 ">{{comment.created_at}}</span>
                    <b-link class="btn btn-icon text-primary rounded-circle p-0 d-none d-lg-block"><feather-icon icon="CornerUpLeftIcon" size="18" @click="addReply(comment)" /></b-link>
                    <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0" style="margin-top: 2px" no-caret>
                  <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle text-muted" v-b-tooltip.hover.v-default title="More Options"/>
                      </template>
                      <b-dropdown-item class="btn-sm p-0" @click="addReply(comment)" >
                        <feather-icon icon="CornerUpLeftIcon" size="13" class="mr-50 text-primary"/>
                        <span class="">Reply to comment</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0" v-if="comment.user.hashid == userDataLocal.hashid" @click="editReply(comment)">
                        <feather-icon icon="EditIcon" size="13" class="mr-50 text-primary"/>
                        <span class="">Edit comment</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0" v-if="selectedOKR.user.hashid == userDataLocal.hashid || comment.user.hashid == userDataLocal.hashid" @click="deleteConfirm(comment,selectedOKR)">
                        <feather-icon icon="XIcon" size="13" class="mr-50 text-danger"/>
                        <span class="">Delete comment</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0"  v-if="selectedOKR.user.hashid == userDataLocal.hashid && comment.visiblity_type == 'Public' " @click="changeStatus(comment,'Private')">
                        <feather-icon icon="LockIcon" size="13" class="mr-50 text-primary"/>
                        <span class="">Make it Private</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="btn-sm p-0"  v-if="selectedOKR.user.hashid == userDataLocal.hashid && comment.visiblity_type == 'Private' " @click="changeStatus(comment,'Public')">
                        <feather-icon icon="BookOpenIcon" size="13" class="mr-50 text-primary"/>
                        <span class="" >Make it Public</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                    <p
                      v-html="comment.comment"
                      class="mb-0 mt-1"
                      style="letter-spacing: 1px; font-size: 14px"
                    ></p>
                    <div>
              </div>
              </div>
            </div>
            <div class="border-top w-100 p-75 mt-50">
            </div>
          </div>
          <div class="reply-cmd">
            <Replies
                      v-if="comment.reply_count"
                      :itemId="comment.hashid"
                      :modelId="selectedOKR.hashid"
                      :reply_count="comment.reply_count"
                      :mentionList="employeeListOptions"
                      v-on:refresh="refresh"
                    />
          <b-collapse  :id="'add-cmd-' + comment.hashid" class="my-50">
          <div class="comment-details w-100 cmt-posts d-flex align-items-end justify-content-center">
            <div class="border p-751 d-flex mb-0 flex-row align-items-center justify-content-around commentBox round w-100">
              <CommentBox 
              placeholder="Reply to comment..." className="w-100 comment-box"
              :toolbar="false"
              :mentionList="employeeListOptions"
              :id="'reply-comment-field-' + comment.hashid"
              />
            </div>
            <b-button
              variant="primary"
              @click="AddOKRComment(comment,selectedOKR)"
              pill
              class="blog-btn-block ml-1 search-post rounded-circle p-75 mb-0"
              :disabled="commentSubmitClicked"
            >
              <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="22"></b-img>
            </b-button>
          </div>
          </b-collapse>
          <b-collapse  :id="'edit-cmd-' + comment.hashid" class="my-50">
          <div class="comment-details w-100 cmt-posts d-flex align-items-end justify-content-center">
            <div class="border p-751 d-flex mb-0 flex-row align-items-center justify-content-around commentBox round w-100">
              <CommentBox 
              placeholder="Reply to comment..." className="w-100 comment-box"
              :toolbar="false"
              :mentionList="employeeListOptions"
              :id="'edit-comment-field-' + comment.hashid"
              :value.sync="comment.comment"
              />
            </div>
            <b-button
              variant="primary"
              @click="editComment(comment, selectedOKR.hashid)"
              pill
              class="blog-btn-block ml-50 search-post save-btn w-auto mb-0"
              :disabled="commentSubmitClicked"
            >
            <strong><b>Save</b></strong>
            </b-button>
          </div>
          </b-collapse>
        </div>
        </b-list-group-item>
      <b-list-group-item v-if="visibleComments.length == 0">
        No Comments to Display.....
        </b-list-group-item>
      </b-list-group>
    </b-card>
    
    <b-card>
        
        <div class="comment-details w-100 cmt-posts d-flex align-items-end justify-content-center">
          <div class="border p-751 d-flex mt-1 mb-0 flex-row align-items-center justify-content-around commentBox round w-100">
            <CommentBox 
            placeholder="Add a comment..." className="w-100 comment-box "
            :toolbar="false"
            :mentionList="employeeListOptions"
            :id="'add-comment-field-' + selectedOKR.hashid"
            />
          </div>
          <b-button
            variant="primary"
            @click="AddOKRComment(null,selectedOKR)"
            class="blog-btn-block ml-1 search-post rounded-circle p-75 mb-0"
            :disabled="commentSubmitClicked"
          >
            <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="22"></b-img>
          </b-button>
        </div>
        </b-card>
  </div>
  <!-- End Comment Section -->
</b-modal>
<!-- Update OKR Progress Popup -->
<b-modal id="update-goal" ref="update_goal" size="lg" centered variant="transparent" @ok.prevent="formUpdated()" ok-title="Update">
  <h4 class="text-center font-weight-bolder"> <b-img-lazy fluid :src="require('@/assets/images/icons/target.png')" alt="goal-img" width="30px" height="30px" style="margin-bottom: 5px"/> Update Objective Progress</h4>
  <b-row>
    <b-col sm="12">
      <h5 class="my-1 font-weight-bolder">Type</h5>
      <b-form-group class="goal-type">
        <div class="d-flex align-items-center">
        <b-form-radio v-model="selectedObjective.type" name="objective" value="Objective">Objective</b-form-radio>
        <b-form-radio v-model="selectedObjective.type" name="experiment and learn" value="Experiment">Experiment and Learn</b-form-radio>
        </div>
      </b-form-group>
    </b-col>
    <!-- <b-col sm="12" class="radio-wrap">
      <div class="form-group">
        <div class="d-flex align-items-center justify-content-between">
          <h5 class="my-1 font-weight-bolder">Status</h5>
        </div>
        <div class="btn-group form-control border-0 p-0" role="group">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="select-status1" value="0" v-model="selectedObjective.status" :checked="selectedObjective.status == 0">
            <label class="form-check-label border-secondary text-secondary rounded" for="select-status1">Not Started Yet</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="select-status2" value="1" v-model="selectedObjective.status" :checked="selectedObjective.status == 1">
            <label class="form-check-label border-warning text-warning rounded" for="select-status2">In Progress</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="select-status3" value="2" v-model="selectedObjective.status" :checked="selectedObjective.status == 2">
            <label class="form-check-label border-primary text-primary rounded" for="select-status3">Completed</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="select-status4" value="3" v-model="selectedObjective.status" :checked="selectedObjective.status == 3">
            <label class="form-check-label border-info text-info rounded" for="select-status4">On Hold</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="select-status5" value="4" v-model="selectedObjective.status" :checked="selectedObjective.status == 4">
            <label class="form-check-label border-danger text-danger rounded" for="select-status5">Cancelled</label>
          </div>
        </div>
      </div>
    </b-col> -->
  </b-row>
  <!-- <b-collapse id="expand-notes">
    <b-row>
      <b-col>
        <div class="form-group">
          <h5 class="font-weight-bolder">Notes</h5>
          <textarea  v-model="selectedObjective.notes" class="form-control" id="requestDescription" rows="2" cols="50" maxlength="250" placeholder="Type your issue description. . . "></textarea>
        </div>
      </b-col>
    </b-row>
  </b-collapse> -->
  <hr>
  <!-- <div class="goal-progress"> -->
  <!-- <h5 class="font-weight-bolder my-1 font-weight-bolder">Progress</h5>
  <div class="goal-detailed-progress d-flex justify-content-between align-items-center">
      <div class="progress-bar mr-1">
        <div class="progress-view" role="progressbar" style="width: 20%;"></div>
      </div>
      <h5 class="progress-report mb-0">0%</h5>
    </div>
  </div> -->
  <h5 class="my-1 font-weight-bolder">Key Results</h5>
    <b-card class="border sub-editer">
      <div class="demo-inline-spacing row">
        <a @click="addSelectedKeyResult()" class="font-weight-bolder text-primary">
        <feather-icon icon="PlusIcon" class="font-weight-bolder"/> Add Key Result</a>
      </div>
      <b-row>
        <b-col cols="12" class="mb-1" v-for="(key_result,key) in selectedObjective.key_results" :key="key">
          <div class="border border-primary mt-1 relative" style="border: 2px solid #38B22D;border-radius: 10px 10px;padding: 15px;">
            <b-row>
              <b-button @click="removeSelectedKeyResult(key)" style="background-color: #fff !important;border-radius: 100%;padding: 0;width: 32px;height: 32px;display: flex;align-items: center;justify-content: center;position: absolute;top: 0;right: 0;"
                >
                <feather-icon icon="XIcon" size="13" class="text-danger" 
                v-b-tooltip.hover.v-danger
                title="Remove Key Result"/>
              </b-button>
              <b-col sm="12">
                <b-form-group
                    :label="'Key Result #'+(key+1)"
                    label-for="key-result"
                  >
                  <b-form-textarea
                    id="key-result"
                    placeholder="Enter the Key Result in Short"
                    v-model="selectedObjective.key_results[key].description"
                    rows="2"
                  />
                  </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group label="Status" label-for="status">
                  <v-select
                    :options="statusList"
                    label="label"
                    :reduce="(label) => label.code"
                    :clearable="true"
                    :searchable="true"
                    input-id="status"
                    name="status"
                    placeholder="Select Status"
                    v-model="selectedObjective.key_results[key].status"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group label="Priority Level" label-for="priority-level">
                  <v-select
                    :options="priorityList"
                    label="label"
                    :reduce="(label) => label.code"
                    :clearable="true"
                    :searchable="true"
                    input-id="priority-level"
                    name="priority-level"
                    placeholder="Select Priority Level"
                    v-model="selectedObjective.key_results[key].priority_level"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4" sm="12">
                <b-form-group label="Timeline" label-for="period_range">
                  <flat-pickr
                    ref="timeline"
                    class="form-control"
                    input-id="timeline"
                    placeholder="Select Start and End Date"
                    v-model="selectedObjective.key_results[key].timeline"
                    :config="keyResultDateConfig"
                  />
                </b-form-group>
              </b-col>
            </b-row>

          <a v-b-toggle :href="'#expand-notes-'+key" @click.prevent class="font-weight-bolder"><feather-icon icon="PlusIcon" class="font-weight-bolder"/> Add Note</a>
          
          <b-collapse :id="'expand-notes-'+key">
            <hr>
            <b-row>
              <b-col>
                <div class="form-group">
                  <h5 class="font-weight-bolder">Notes</h5>
                  <textarea  v-model="selectedObjective.key_results[key].notes" class="form-control" id="requestDescription" rows="2" cols="50" maxlength="250" placeholder="Type your issue description. . . "></textarea>
                </div>
              </b-col>
            </b-row>
          </b-collapse>
          </div>
        </b-col>
      </b-row>
      <div class="empty-data d-flex align-items-center justify-content-center">
        <b-img-lazy fluid :src="require('@/assets/images/icons/key.png')" alt="key-note" size="5rem"/>
        <div class="empty-title ml-1">
          <h6 class="font-weight-bolder">Key results help you measure your progress toward the goal</h6>
          <p class="small">Edit your goal to create key results.</p>
        </div>
      </div>
    </b-card>  
</b-modal>
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import Replies from "@/views/okrs/Replies.vue";


import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
  BInputGroup,
  VBTooltip,
  BInputGroupAppend,
  BModal,
  BTable,
  BButton,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BProgressBar,
  BProgress,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import { required, email, numeric } from "@validations";
import store from "@/store";
import okrStoreModule from "../okrStoreModule";
import bomb from "@/libs/bomb/bomb";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import axios from "@axios";
import Vue from "vue";
import Ripple from "vue-ripple-directive";
import VueCookies from "vue-cookies";
import { D } from "caniuse-lite/data/agents";
import { VueGoodTable } from 'vue-good-table';
import CommentBox from "@/components/CommentBox.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mapWatchers } from 'vue';
import Node from "./node.vue";
Vue.use(VueCookies);
import $ from "jquery";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BFormCheckbox,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    ToastificationContent,
    StarRating,
    flatPickr,
    BModal,
    BTable,
    BButton,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    CommentBox,
    Replies,
    AppCollapse,
    AppCollapseItem,
    Node,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mounted () {
  // Custom Month & Year Selectbox
  

function DropDown(el) {
    this.dd = el;
    this.placeholder = this.dd.children('span');
    this.opts = this.dd.find('ul.drop li');
    this.val = '';
    this.index = -1;
    this.initEvents();
}

DropDown.prototype = {
    initEvents: function () {
        var obj = this;
        obj.dd.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $(this).toggleClass('active');
        });
        obj.opts.on('click', function () {
            var opt = $(this);
            obj.val = opt.text();
            obj.index = opt.index();
            obj.placeholder.text(obj.val);
            opt.siblings().removeClass('selected');
            opt.filter(':contains("' + obj.val + '")').addClass('selected');
        }).change();
    },
    getValue: function () {
        return this.val;
    },
    getIndex: function () {
        return this.index;
    }
};

$(function () {
    // create new variable for each menu
    var dd1 = new DropDown($('#custom-select'));
    // var dd2 = new DropDown($('#other-gases'));
    $(document).click(function () {
        // close menu on document click
        $('.wrap-drop').removeClass('active');
    });
});
    
    $(function () {
      $('.teamview-tree ul, .tree-goal ul').hide();
      $('.teamview-tree>ul, .tree-goal>ul').show();
      $('.teamview-tree ul.active').hide();
      // $('.teamview-tree li, .tree-goal li').on('click', function (e) {
      //   $(this).addClass("open-tree"); 
      //   $(this).siblings('li').removeClass('open-tree');
      //   $(this).siblings('li').find('ul').hide();
      //     var children = $(this).find('> ul');
      //     if (children.is(":visible")) children.hide('fast').removeClass('active');
      //     else children.show('fast').addClass('active');
      //     e.stopPropagation();
      // }); 
    });
    if (this.$route.query.okr_hash_id) {
      this.openOKR(this.$route.query.okr_hash_id);
    }
  },
  
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-okrs";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, okrStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    this.getOrganizationsParents();
    this.getOKRbyType('all');
    this.fetchEmployeeList();
    this.fetchManagerList()
    this.$store
      .dispatch("app/fetchEmployeeList")
      .then((res) => {
        this.employeeOptions = res.data.data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  data(){
    return {
      searchQuery:'',
      stateFilter : '',
      okrStateOption : [],
      employeeFilter : '',
      employeeOptions : [],
      managerFilter : '',
      managerOptions :[],
      monthFilter : '',
      monthOptions: [
        {
          label: "January",
          code: "1",
        },
        {
          label: "February",
          code: "2",
        },
        {
          label: "March",
          code: "3",
        },
        {
          label: "April",
          code: "4",
        },
        {
          label: "May",
          code: "5",
        },
        {
          label: "June",
          code: "6",
        },
        {
          label: "July",
          code: "7",
        },
        {
          label: "August",
          code: "8",
        },
        {
          label: "September",
          code: "9",
        },
        {
          label: "October",
          code: "10",
        },
        {
          label: "November",
          code: "11",
        },
        {
          label: "December",
          code: "12",
        },
      ],
      yearFilter : new Date().getFullYear(),
      typeFilter:'',
      typeOptions:[
        {
          label: "Organizational OKR",
          code: "Organizational OKR",
        },
        {
          label: "Team OKR",
          code: "Team OKR",
        },
        {
          label: "Individual OKR",
          code: "Individual OKR",
        }
      ],
      parentFilter:'',
      selectedDate:'',
      parentFilterOption:[],
      listType : '',
      completionValue : 0,
      lastPage: 10,
      per_page: 10,
      total: 0,
      nextPage: 1,
      currentPage: 1,
      meta:[],
      roots : [],
      selectedStatus : '',
      selectedType : '',
      fullScreen:false,
      rootList:[],
      selectedRoot:'',
      okrs:[],
      userDataLocal: this.$cookies.get("userData"),
      newComment:'',
      replyComment:'',
      employeeListOptions:[],
      visibleComments:[],
      priorityList:[
        {
          label: "Low",
          code: 0,
        },
        {
          label: "Medium",
          code: 1,
        },
        {
          label: "High",
          code: 2,
        },
      ],
      selectedObjective:{
          'type':'',
          'objective':'',
          'objective_description':'',
          'key_result':'',
          'key_result_description':'',
          'status':'',
          'notes':'',
          'metric_type':'',
          'start':'',
          'end':'',
        },
      ratingdescription: [
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Below Average",
          class: "star-poor",
        },
        {
          text: "Average",
          class: "star-belowAverage",
        },
        {
          text: "Good",
          class: "star-average",
        },
        {
          text: "Very Good",
          class: "star-good",
        },
        {
          text: "Excellent",
          class: "star-excellent",
        },
      ],
      is_loading: {
        submit: false,
      },
      redirectOkr: "",
      data_local: {
        title:'',
        description:'',
        period_range:'',
        okr_type:'',
        okr_owner:'',
        parent_okr_type:'',
        parent_okr:'',
        okr_details:[{
          'type':'',
          'objective':'',
          'objective_description':'',
          'key_results':[{
            'description' : '',
            'status':'',
            'priority_level':'',
            'timeline':''
          }],
          'key_result_description':'',
          'status':'',
          'notes':'',
          'metric_type':'',
          'start':'',
          'end':'',
        }],
        get_customfields_data: [],
      },
      dateConfig: {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        //inline: true,
        mode: 'range'
      },
      keyResultDateConfig: {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        //inline: true,
        mode: 'range',
      },
      okrTypeList:[
        {
          label: "Organizational OKR",
          code: "Organizational OKR",
        },
        {
          label: "Team OKR",
          code: "Team OKR",
        },
        {
          label: "Individual OKR",
          code: "Individual OKR",
        }
      ],
      parentOkrTypeList:[
        {
          label: "Organizational OKR",
          code: "Organizational OKR",
        },
        {
          label: "Team OKR",
          code: "Team OKR",
        },
        {
          label: "Individual OKR",
          code: "Individual OKR",
        }
      ],
      typeList:[
        {
          label: "Objective",
          code: "Objective",
        },
        {
          label: "Experiment and Learn",
          code: "Experiment",
        }
      ],
      statusList:[
        {
          label: "Not Started Yet",
          code: 0,
        },
        {
          label: "In Progress",
          code: 1,
        },
        {
          label: "Completed",
          code: 2,
        },
        {
          label: "On Hold",
          code: 3,
        },
        {
          label: "Cancelled",
          code: 4,
        },
      ],
      customFields: [],
      modes: ['multi', 'single', 'range'],
      fields: ['selected', 'type', { key: 'objective', label: 'Title' },{ key: 'status', label: 'Status' }],
      items: [],
      status: [{
        0:'Not Started Yet' ,1: 'In Progress', 2: 'Completed', 3: 'On Hold', 4: 'Cancelled',
      },
      {
        0:'dark' , 1: 'warning', 2: 'success', 3: 'info', 4: 'danger',
      }],
      selectMode: 'multi',
      selected: [],
      selectedOKR: {
        title:'',
        description:'',
        period_range:'',
        okr_type:'',
        okr_owner:'',
        parent_okr_type:'',
        parent_okr:'',
        okr_details:[{
          'type':'',
          'objective':'',
          'objective_description':'',
          'key_results':[{
            'description' : '',
            'status':'',
            'priority_level':'',
            'timeline':''
          }],
          'key_result_description':'',
          'status':'',
          'notes':'',
          'metric_type':'',
          'start':'',
          'end':'',
        }],
        user:{
          avatar:"",
        },
        get_customfields_data: [],
      },
      objectives:[],
      experiments:[],
      myOKRs:[],
      parentOptions:[],
      metricOption: [
        { text: '123', value: 'Numeric' },
        { text: '%', value: 'Percentage' },
      ],
      employeeOptions:[],
      nodes:null,
      isListViewOpen:false,
      commentSubmitClicked:false,
    }
  },
  watch: {
    currentPage(val) {
      this.getOKRList();
    },
    stateFilter(val) {
      this.getOKRList();
    },
    employeeFilter(val) {
      this.getOKRList();
    },
    managerFilter(val) {
      this.getOKRList();
      if (val == null) {
        this.fetchEmployeeList();
      } else {
        this.fetchTeamMates(val);
      }
    },
    monthFilter(val) {
      this.getOKRList();
    },
    yearFilter(val) {
      this.getOKRList();
    },
    listType(val) {
      this.getOKRList();
    },
    completionValue(val) {
      this.getOKRList();
    },
    typeFilter(val) {
      this.getOKRList();
    },
    parentFilter(val) {
      this.getOKRList();
    },
    selectedDate(val) {
      this.getOKRList();
    },
    selectedRoot(val){
      this.getTreeParents();
      this.getOKRTree(val);
    },
    searchQuery(val) {
      if (val.length > 2 || val.length == 0) {
        this.getOKRList();
      }
    },
  },
  computed:{
    
    RootList(){
      this.rootList = [];
      this.selectedRoot = this.roots[0].hashid;
      for(var index in this.roots){
        this.rootList.push({
          'label' : this.roots[index].title,
          'code' : this.roots[index].hashid,
        })
      }
      return this.rootList;
    },
    VisibleComments(){
      this.visibleComments = [];
      if(this.selectedOKR.comments){
        for(var index in this.selectedOKR.comments){
          if(this.isVisible(this.selectedOKR.comments[index])){
            this.visibleComments.push(this.selectedOKR.comments[index]);
          }
        }
      }
      return this.visibleComments;
    },
    getObjectives(){
      this.objectives = [];
      if(this.selectedOKR){
        for(var key in this.selectedOKR.okr_details){
          if(this.selectedOKR.okr_details[key].type == 'Objective'){
            this.objectives.push(this.selectedOKR.okr_details[key]);
          }
        }
        return this.objectives;
      }
      return this.objectives;
    },
    getExperiments(){
      this.experiments = [];
      if(this.selectedOKR){
        for(var key in this.selectedOKR.okr_details){
          if(this.selectedOKR.okr_details[key].type == 'Experiment'){
            this.experiments.push(this.selectedOKR.okr_details [key]);
          }
        }
        return this.experiments;
      }
      return this.experiments;
    },
    
  },
  methods: {
    showOkrForm() {
      this.$refs.okrname.focus()
    },
    getOKR(okr){

      axios
        .get("okrs/"+okr.hashid)
        .then((response) => {
            this.selectedOKR = response.data.data
        });
    },
    openOKR(okr_hashid){
      axios
      .get("okrs/"+okr_hashid)
      .then((response) => {
          this.selectedOKR = response.data.data;
          this.$bvModal.show('goal-details');
      });
    },
    getOKRTree(parent){
      axios
        .get("OKRs/get-tree/"+parent)
        .then((response) => {
          if (response.data.success) {
            this.nodes = response.data.data;
            this.opened = false;
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch OKR Tree",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch OKR Tree"',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    getOrganizationsParents(){
      axios
        .post("OKRs?page="+this.currentPage,
          {
           type : 'Organizational OKR',
           only_parents : true,
          })
        .then((response) => {
          if (response.data.success) {
            this.roots = response.data.data
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch Root OKRs",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch Root OKRs',
                icon: 'BellIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    getOKRList(){
      var url = "OKRs?withChildrenLine=1&";
      if(this.currentPage){
        url += "page="+this.currentPage+"&";
      }
      if(this.searchQuery){
        url += "q="+this.searchQuery+"&";
      }
      if(this.stateFilter || this.stateFilter == 0){
      url += "state="+this.stateFilter+"&";
      }
      if(this.employeeFilter){
      url += "employee="+this.employeeFilter+"&";

      }
      if(this.managerFilter){
      url += "manager="+this.managerFilter+"&";
      }
      if(this.typeFilter){
      url += "type="+this.typeFilter+"&";
      }
      if(this.parentFilter){
      url += "parentFilter="+this.parentFilter+"&";
      }
      if(this.selectedDate){
      url += "selectedDate="+this.selectedDate+"&";
      }
      if(this.monthFilter){
      url += "month="+this.monthFilter+"&";

      }
      if(this.yearFilter){
      url += "year="+this.yearFilter+"&";

      }
      if(this.listType){
      url += "listType="+this.listType+"&";

      }
      if(this.completionValue){
        url += "completionValue="+this.completionValue;
      }


      axios
        .post(url,
          {
           parent_id : this.selectedRoot,
          })
        .then((response) => {
          if (response.data.success) {
            this.okrs = response.data.data;
            if(response.data.meta){
              this.nextPage = response.data.meta.current_page + 1;
              this.lastPage = response.data.meta.last_page;
              this.per_page = response.data.meta.per_page;
              this.currentPage = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.meta = response.data.meta;  
            }       
            if(response.data.state){
              this.okrStateOption = response.data.state;
            } 
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch OKRs",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch OKRs',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    okrOpened(okr){
      this.selectedOKR = okr;
      this.getOKRbyType(okr.parent_okr_type);
    },
    resetVariables(){
      this.selectedOKR = {};
      this.selectedObjective = {};
    },
    addReply(comment) {
      const self = this;
      self.comment_id = comment.hashid;
      self.reply_to = comment.user.id;
      $('#add-cmd-'+comment.hashid).show();
      $('#edit-cmd-'+comment.hashid).hide();
    },
    editReply(comment) {
      const self = this;
      self.comment_id = comment.hashid;
      $('#add-cmd').hide();
      $('#edit-cmd-'+comment.hashid).show();
      document.getElementById('edit-comment-field-' + comment.hashid).focus();
    },
    deleteConfirm(comment,okr){
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete this comment`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteComment(comment.hashid,okr.hashid);
        }
      });
    },

    deleteComment(commentId, okrId) {
      this.$http
        .delete(`/okr/${okrId}/comments/${commentId}`)
        .then((res) => {
          this.visibleComments = [];
          this.selectedOKR = res.data.data;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Comment deleted successfully",
          //     icon: "BellIcon",
          //     variant: "success",
          //   },
          // });
          this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Comment deleted successfully',
          icon: 'BellIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      }); 
        });
    },
    
    changeStatus(comment,status){
      let params = {};
      params = {
        comment: comment.comment,
        visiblity_type:status
      };

      this.$http
        .patch(`okr/comment/${comment.hashid}`, params)
        .then((res) => {
          this.visibleComments = [];
          for (let index = 0; index < this.selectedOKR.comments.length; index++) {
            if(this.selectedOKR.comments[index].hashid == comment.hashid){
              this.selectedOKR.comments[index].visiblity_type = status;
            }            
          }
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment Update Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment Update Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment Update Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      return true;
    },
    editComment(comment, okr_id) {
      this.commentSubmitClicked=true;
      const self = this;
      const message = document.getElementById(
        `edit-comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: "Reply cannot be empty",
        //     icon: "BellIcon",
        //     text: "Please enter the comments",
        //     variant: "danger",
        //   },
        // });
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Reply cannot be empty',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Please enter the comments",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        this.commentSubmitClicked=false;
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`okr/comment/${comment.hashid}`, params)
        .then((res) => {
          // this.editComments.pop();
          this.visibleComments = [];
          this.selectedOKR =  res.data.data;
          this.comment_id = null;
          document.getElementById(`edit-comment-field-${comment.hashid}`).value = "";
          $(".ql-editor").html("");
          $('#add-cmd').hide();
          $('#edit-cmd-'+comment.hashid).hide();
          this.refresh();
          this.commentSubmitClicked=false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked=false;
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                 
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      return true;
    },
    refresh(){
      this.$router.go();
    },
    AddOKRComment(cmd,okr) {
        this.commentSubmitClicked=true;
      if(cmd){
       var comment = document.getElementById(`reply-comment-field-${cmd.hashid}`).value;
      }else{
      var comment = document.getElementById(`add-comment-field-${okr.hashid}`).value;
      }
      let params = {};
      params = {
        comment: comment,
        parent_comment_id: this.comment_id,
      };
      this.$http
        .post(`/okr/${okr.hashid}/comments`, params)
        .then((res) => {
          this.visibleComments = [];
          this.selectedOKR.comments = res.data.data.comments;
          this.comment_id = null;
          if(cmd){
            document.getElementById(`reply-comment-field-${cmd.hashid}`).value = '';
          }
          else{
            document.getElementById(`add-comment-field-${okr.hashid}`).value = '';
          }
          $(".ql-editor").html("");
          $('#add-cmd').hide();
        this.commentSubmitClicked=false;
        })
        .catch((error) => {
        this.commentSubmitClicked=false;
          // Do something on error
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: "OKR Comment adding Failed",
                  //     icon: "BellIcon",
                  //     text: values[0][0],
                  //     variant: "danger",
                  //   },
                  // });
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: values[0][0],
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              }
            }
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Comment adding Failed",
            //     icon: "BellIcon",
            //     text: "Oops! Something Went Wrong",
            //     variant: "danger",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Comment adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
      return true;
    },
    fetchEmployeeList() {
      const self = this;
      let data = {};
      data.url = "list/user-list-for-tag-names";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.title}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.hashid);
        }
      });
    },
    isCreator(okr) {
      if (okr.user.hashid == this.userDataLocal.hashid) {
        return true;
      }
      return false;
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-okrs/removeOkr", id)
        .then((res) => {
          if (res.status == "204") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "OKR Deleted Successfully.",
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'OKR Deleted Successfully',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          this.getMyOkr();
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "OKR Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });

          this.$toast({
        component: ToastificationContent,
        props: {
          title: 'OKR Deletion failed',
          icon: 'BellIcon',
          variant: 'danger',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"error-info",
        hideProgressBar : true,
      }); 

          console.error(err);
        });
    },
    getStatusClass(status){
    if (status == 0) return "dark";
    if (status == 1) return "warning";
    if (status == 2) return "success";
    if (status == 3) return "info";
    if (status == 4) return "danger";
    return "primary";
    },
    getPriorityClass(status){
    if (status == 0) return "success";
    if (status == 1) return "warning";
    if (status == 2) return "danger";
    return "primary";
    },
    getPriorityLabel(status){
      var label = "Low";
      for(var key in this.priorityList){
        if(status == this.priorityList[key].code){
          label = this.priorityList[key].label
        }
      }
      return label
    },
    getStatusLabel(status){
      var label = "Not Started Yet";
      for(var key in this.statusList){
        if(status == this.statusList[key].code){
          label = this.statusList[key].label
        }
      }
      return label
    },
    getOKRbyType(type){
      var startDate = this.selectedOKR.period_range.split(' to ')[0];
      axios
        .post("OKRs/by_type",{ okr_type : type, selectedDate:startDate})
        .then((response) => {
          if (response.data.success) {
            this.parentOptions = response.data.data
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch OKRs for parent",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch OKRs for parent',
                icon: 'EditIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

          }
        });
    },
    getMyOkr(){
      axios
        .get("myOKRs")
        .then((response) => {
          if (response.data.success) {
            this.myOKRs = response.data.data
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch your OKRs",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch your OKRs',
                icon: 'EditIcon',
                variant: 'danger',
                text:response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                
          }
        });
    },
    formSubmitted() {
      const self = this;
      var validData = this.checkData(self.data_local);
      if(validData.status){
        const formData = new FormData(document.getElementById("okr-form"));
        formData.append("okr_details", JSON.stringify(self.data_local.okr_details));
        formData.append("period_range", self.data_local.period_range);
        formData.append("okr_type", self.data_local.okr_type);
        formData.append("okr_owner", self.data_local.okr_owner);
        formData.append("title", self.data_local.title);
        // formData.append("description", self.data_local.description);
        formData.append("parent_okr_type", self.data_local.parent_okr_type);
        formData.append("parent_okr", self.data_local.parent_okr);
        this.is_loading.submit = true;
        this.$store
          .dispatch("app-okrs/addOkr", formData)
          .then((res) => {
            this.is_loading.submit = false;
            this.$router.push("/individual-okrs");
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Added Successfully",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Added Successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            this.getMyOkr();
          })
          .catch((error) => {
            this.is_loading.submit = false;
            // this.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: "Oops! OKR Adding Failed",
            //       icon: "BellIcon",
            //       variant: "danger",
            //       text: error,
            //     },
            //   });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! OKR Adding Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          });

      }
      else{
        for(var key in validData.messages){
        //  self.$toast(
        //     {
        //       component: ToastificationContent,
        //       props: {
        //         title: validData.messages[key].title,
        //         text:validData.messages[key].text,
        //         icon: "BellIcon",
        //         variant: "danger",
        //       },
        //     }); 
        this.$toast({
              component: ToastificationContent,
              props: {
                title: validData.messages[key].title,
                icon: 'BellIcon',
                variant: 'danger',
                text: validData.messages[key].text,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      }
    },
    formUpdated() {
      const self = this;
      var validData = this.checkData(self.selectedOKR);
      if(validData.status){
        const formData = new FormData();
        formData.append("okr_details", JSON.stringify(self.selectedOKR.okr_details));
        formData.append("period_range", self.selectedOKR.period_range);
        formData.append("okr_type", self.selectedOKR.okr_type);
        formData.append("okr_owner", self.selectedOKR.okr_owner);
        formData.append("title", self.selectedOKR.title);
        // formData.append("description", self.selectedOKR.description);
        formData.append("parent_okr_type", self.selectedOKR.parent_okr_type);
        formData.append("parent_okr", self.selectedOKR.parent_okr_hashid);
        formData.append("_method", "PATCH");
        this.is_loading.submit = true;
        this.$swal({
          title: "Would you like to send OKR update emails to Users?",
          // text: `You want to delete.`,
          // icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Send",
          cancelButtonText: "Don't send",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger mr-1",
          },
          reverseButtons: true,
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            formData.append("sendNotifications", true);
          } else {
            formData.append("sendNotifications", false);
          }
          this.$store
          .dispatch("app-okrs/updateOkr", {
            id: self.selectedOKR.hashid,
            data: formData,
          })
          .then((res) => {
            this.is_loading.submit = false;
            this.selectedObjective={};
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "OKR Updated",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'OKR Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
            this.$refs.update_goal.hide();
            this.$refs.goal_details.hide();
            this.getOKRList();
          })
          .catch((error) => {
            this.is_loading.submit = false;
            // this.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: "Oops! OKR updating Failed",
            //       icon: "BellIcon",
            //       variant: "danger",
            //       text: error,
            //     },
            //   });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! OKR updating Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          });
        }); 

      }
      else{
        for(var key in validData.messages){
        //  self.$toast(
        //     {
        //       component: ToastificationContent,
        //       props: {
        //         title: validData.messages[key].title,
        //         text:validData.messages[key].text,
        //         icon: "BellIcon",
        //         variant: "danger",
        //       },
        //     }); 
        this.$toast({
              component: ToastificationContent,
              props: {
                title: validData.messages[key].title,
                icon: 'BellIcon',
                variant: 'danger',
                text: validData.messages[key].text,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        }
      }
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getCustomFields() {
      const self = this;
      self.$store
        .dispatch("app-okrs/getCustomFields")
        .then((res) => {
          self.customFields = res.data.data;
          self.okrStateOption = res.data.meta.status;
        })
        .catch((error) => {
          console.log(error);
          // self.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! Custom Fields Getting Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! Custom Fields Getting Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    getUserPreviousOKRPendingObjectives(){
      const self = this;
      self.$store
        .dispatch("app-okrs/getUserPreviousOKRPendingObjectives")
        .then((res) => {
          self.items = res.data.data;
          self.$refs['addToCurrentMonth'].show();
        })
        .catch((error) => {
          console.log(error);
          // self.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! unable to get pending objective from old records Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Oops! unable to get pending objective from old records Failed`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
        });
    },
    addOnSelected() {
      this.selectedOKR.okr_details.push({
          'type':'Objective',
          'objective':'',
          'objective_description':'',
          'key_results':[{
            'description' : '',
            'status':0,
            'priority_level':0,
            'timeline':''
          }],
        })
    },
    add() {
      this.data_local.okr_details.push({
          'type':'Objective',
          'objective':'',
          'objective_description':'',
          'key_results':[{
            'description' : '',
            'status':0,
            'priority_level':0,
            'timeline':''
          }],
        })
      this.$toast.success("Empty Objective Added");
    },
    copy(key) {
      this.data_local.okr_details.push(this.data_local.okr_details[key])
    },
    remove(index) {
      this.data_local.okr_details.splice(index, 1)
    },
    removeFromSelected(index) {
      this.selectedOKR.okr_details.splice(index, 1)
    },
    checkData(data) {
      var messages = [];

      var selectedDate = data.period_range;
      if (!selectedDate){
        messages.push({'title':'Oops! We need a Date range for OKR','text':'Please select Period Start and End Date'});
        response = {"status":false,'messages':messages};
        return response;        
      }
      var dates = selectedDate.split(" to ");
      if(dates.length != 2){
        messages.push({'title':'Oops! We need a Date range for OKR','text':'Please select Period Start and End Date'});
      }
      
      if(data.parent_okr_type && data.okr_type !="Organizational OKR" && ((data.parent_okr == '' || data.parent_okr == null || data.parent_okr == undefined) && (data.parent_okr_hashid == '' || data.parent_okr_hashid == null || data.parent_okr_hashid == undefined))){
        messages.push({'title':"Parent OKR is Required ",'text':"Please Select Parent to link this OKR with tree"});
      }
      data.okr_details.forEach(function(obj,key) { 
        key = key+1;
        if(obj.type == '' || obj.type == null || obj.type == undefined){
          messages.push({'title':"Type is Required for Objective "+key,'text':"Please Select Type for Objective "+key});
        }
        
        // if(obj.status !=0 && (obj.status == '' || obj.status == null || obj.status == undefined)){
        //   messages.push({'title':"Satus is Required for Objective "+key,'text':"Please Select Status for Objective "+key});
        // }
        if(obj.objective == '' || obj.objective == null || obj.objective == undefined){
          messages.push({'title':"Objective Title is Required for Objective "+key,'text':"Please Select objective title for Objective "+key});
        }
        if(obj.key_results.length == 0){
          messages.push({'title':"key Result is Required for Objective "+key,'text':"Please Enter atleast one Key Result for Objective "+key});
        }
        for (let index = 0; index < obj.key_results.length; index++) {
          if(obj.key_results[index].description == '' || obj.key_results[index].description == null || obj.key_results[index].description == undefined){
            messages.push({'title':"Please Enter Description",'text':"Description is required for Key Result #"+(index+1)});
          }
          if(obj.key_results[index].timeline == '' || obj.key_results[index].timeline == null || obj.key_results[index].timeline == undefined){
            messages.push({'title':"Please Select Timeline",'text':"Timeline is required for Key Result #"+(index+1)});
          }          
        }
        
        });

      var response = {"status":false,'messages':messages};
      if(messages.length == 0){
        var response = {"status":true,'messages':messages};
      }
      return response;      
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    AddObjective() {
      this.data_local.okr_details = this.selected;
      // for (let index = 0; index < this.selected.length; ++index) {
      //   this.data_local.okr_details.push(this.selected[index]);
      //   console.log(this.data_local.okr_details);
      // };
      this.$refs['addToCurrentMonth'].hide();
    },
    isVisible(comment){
      if(comment.visiblity_type == 'Public'){
        return true;
      }else{
        if(comment.user.hashid == this.userDataLocal.hashid){
          return true;
        }
        if(this.selectedOKR.user.hashid == this.userDataLocal.hashid){
          return true;
        }
      }
    },

    addKeyResult(key,type){
      if(type == 'add'){
        this.data_local.okr_details[key].key_results.push(
          {
            'description' : '',
            'status':'',
            'priorty_level':'',
            'time_line':''
          }
        );
      }
      if(type == 'update'){
        this.selectedOKR.okr_details[key].key_results.push(
          {
            'description' : '',
            'status':'',
            'priorty_level':'',
            'time_line':''
          }
        );
      }
      this.$toast.success("Empty Key Result Added");
    },
    removeKeyResult(key,index,type){
      if(type == 'add'){
        this.data_local.okr_details[key].key_results.splice(index, 1)
      }
      if(type == 'update'){
        this.selectedOKR.okr_details[key].key_results.splice(index, 1)
      }
    },

    
    getObjectiveRatio(Objective){
      var count = Objective.key_results.length;
      var score = 0;
      Objective.key_results.forEach(element => {
        if(element.status == 2){
          score = score +1;
        }
        else if(element.status == 4){
          count = count-1;
        }
      });
      return "( "+score+" / "+count+" )";
    },
    getObjectiveStatus(Objective){
      var count = Objective.key_results.length;
      var countOfInProgress = 0;
      var countOfPending = 0;
      var countOfHold = 0;
      var countOfCancelled = 0;

      var score = 0;
      Objective.key_results.forEach(element => {
        if(element.status == 2){
          score = score +1;
        }
        else if(element.status == 1){
          countOfInProgress = countOfInProgress + 1;
        }
        else if(element.status == 0){
          countOfPending = countOfPending + 1;
        }
        else if(element.status == 3){
          countOfHold = countOfHold + 1;
        }
        else if(element.status == 4){
          countOfCancelled = countOfCancelled + 1;
          count = count-1;
        }
      });
      if(countOfPending == count){
        return 0;
      }else if(countOfInProgress == count){
        return 1;
      }else if(score == count){
        return 2;
      }else if(countOfHold == count){
        return 3;
      }else if(countOfCancelled == count){
        return 4;
      }
      var  percentage = (score / count) * 100;
      if(percentage > 0){
        return 1;
      }
      else if(percentage == 100){
        return 2;
      }
      else{
        return 0;
      }
    },
    reset(){
      this.stateFilter = '';
      this.employeeFilter = '';
      this.managerFilter = '';
      this.monthFilter = '';
      this.yearFilter = new Date().getFullYear();
      this.listType = '';
      this.completionValue = 0;
      this.typeFilter='';
      this.selectedDate='';
      this.parentFilter ='';
    },
    fetchManagerList() {
      this.$store
        .dispatch("app/fetchManagerList")
        .then((res) => {
          this.managerOptions = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchTeamMates(manager_id) {
      var query = { manager_id: manager_id };
      this.$store
        .dispatch("app/fetchTeamMates", query)
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTreeParents(){
      axios
        .post("/tree-parents",
          {
           parent_id : this.selectedRoot,
          })
        .then((response) => {
          if (response.data.success) {
            this.parentFilterOption = response.data.data;
          } else {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Unable to fetch Tree Parent OKRs",
            //     icon: "EditIcon",
            //     variant: "danger",
            //     text: response.data.message,
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to fetch Tree Parent OKRs',
                icon: 'BellIcon',
                variant: 'danger',
                text: response.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
        });
    },
    
    listClicked(id){
      var nodeId = '#'+id;
      $(nodeId).addClass("open-tree");
      $(nodeId+"-node-arrow").toggleClass("activenode");
      $(nodeId).siblings('li').removeClass('open-tree');
      $(nodeId).siblings('li').find('ul').hide();
        var children = $(nodeId).find('> ul');
        if (children.is(":visible")) children.hide('fast').removeClass('active');
        else children.show('fast').addClass('active');
    },
    rangeChanged(val){
      var start  = val.split(' to ')[0];
      var end  = val.split(' to ')[1];
      if(start && end){
        this.keyResultDateConfig={
          altInput: true,
          altFormat: "F j, Y",
          dateFormat: "Y-m-d",
          //inline: true,
          mode: 'range',
          enable: [
          {
              from: start,
              to: end
          }
        ]
        };
      }
    },
    parentTypeChanged(){
      if(this.selectedOKR.parent_okr){
        if(this.selectedOKR.parent_okr_type != this.selectedOKR.parent_okr.type){
          this.selectedOKR.parent_okr_hashid = '' ;
        }else{
          this.selectedOKR.parent_okr_hashid = this.selectedOKR.parent_okr.hashid ;
        }
      }else{
        this.selectedOKR.parent_okr_hashid = '' ;
      }
      this.getOKRbyType(this.selectedOKR.parent_okr_type,'update');
    },

    shareUrl(hash_id) {
      
      return window.location.origin + "/team-okrs?okr_hash_id=" + hash_id;
    },
    copyURL(hash_id){
      var url = window.location.origin + "/team-okrs?okr_hash_id=" + hash_id;
      navigator.clipboard.writeText(url);
      this.onCopy();
    },
    onCopy: function (e) {
      this.$toast.success("You just copied URL to the clipboard");
    },
    onError: function (e) {
      this.$toast.error("Failed to copy the URL to the clipboard");
      console.log(e);
    },
    addSelectedKeyResult(){
        this.selectedObjective.key_results.push({
            'description' : '',
            'status':0,
            'priorty_level':0,
            'time_line':''
          });
    },
    removeSelectedKeyResult(index){
        this.selectedObjective.key_results.splice(index, 1)
    },
    getOKRIcon(okr){
      if(!okr.okr_type){
        okr.okr_type = okr.type;
      }
      if(okr.okr_type == 'Individual OKR'){
        return okr.user.avatar;
      }
      else if(okr.okr_type == 'Team OKR'){
        return require('@/assets/images/icons/okr/okr-team.png');
      }
      else if(okr.okr_type == 'Organizational OKR'){
        return require('@/assets/images/icons/okr/okr-company.png');
      }
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
@import "@core/scss/vue/pages/page-auth.scss";
</style>